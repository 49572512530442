<template>
    <div class="cms">
        <div class="container">
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>CMS SOLUTIONS</h4>
                        <h6>Powerful content management solutions</h6>
                        <p>Regardless of your preferred platform, REDfOXES design and software engineering services ensures that you get the best of that platform. We hold a portfolio of clients in every major content management systems.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Fully Custom CMS Solutions" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>We customize from scratch: No templates here. Building from the ground up gives your business the edge that only a precise, cutting edge design can offer. Our custom content management systems solutions offer a fast, secure, fully catered dashboard that not only manages your online business but also fully integrates into all your business work-flows.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/CMS/CMS.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Sitecore CMS">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Sitecore is an underutilized omnichannel automation content management system that can make your small operation function like an international conglomerate. redfoxes will customize the Sitecore Web Experience Manager to work for you, delivering a system with enterprise level services for any size company.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/CMS/CMS SITECORE.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Drupal CMS">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Drupal works exceptionally well with companies that need a flexible system for upscaling business. The Drupal platform allows for easy upgrades, as it is extremely customizable. In terms of scalability, flexibility, reliability, manageability, security, interoperability, and availability of resources; Drupal is an enterprise-class CMS.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/CMS/CMS DRUPAL.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Magento eCommerce CMS">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Magento is the go to system for online web shops. Through customized application of the Magento Commerce System, REDFOXES creates and deploys your unique web portal. We are also well versed in the wide array of extensions that are available for Magento. We will customize each individual app as well as your application stack around the individual needs of your business.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/CMS/CMS MAGENTO.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Demandware CMS">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>The Demandware Commerce Cloud has the potential to give you a cost effective, agile platform that shines in the cloud. REDFOXES is the design team that can simplify the process of migration into the cloud or expansion within it. If you have dreamed of upgrading your business into the cloud, REDFOXES is the partner you need to get you there with Demandware.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/CMS/CMS DEMANDWARE.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="WordPress CMS">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>WordPress powers 22% of the Internet for a reason - it is streamlined and straightforward. With REDFOXES navigating the power of this incredible platform, you can have any website you want: an elegant personal blog or a fully functional business ecommerce site. We ensure optimal functionality, search engine friendly design and bug free scalability.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/CMS/CMS WORDPRESS.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row choosing-cms">
                <div class="col-12">
                    <div class="cms-txt">
                        <h4>Choosing the right Content Management System!</h4>
                        <p>
                            Content management systems (CMS) provide the framework for the creation, organization, publishing, distribution and management of your digital content.
                            The Content Management System platform you choose creates the infrastructure of your online presentation. Each content management system platform also comes equipped with proprietary tools to populate your onsite content and direct your business.
                            The framework is there, but how should you use it? This is where Redfoxes can help your business.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row why-redfoxes">
                <div class="col-12">
                    <div class="why">
                        <h4>Why RedFoxes?</h4>
                        <p>
                            Redfoxes first helps you determine the right content management systems for your company. Our dedicated, experienced web designers then tailor that content management systems specifically to your business, filling in the gaps between your core competencies and bolstering your company's ability to communicate with and sell to your customers. We have the ability to dive beneath the surface of your chosen content management systems to tweak its functionality at a core level, ensuring that your website achieves the deepest and most relevant application possible within that platform.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row work-together">
                <div class="col-12">
                    <div class="work">
                        <h4>Let’s work together</h4>
                        <p>Here at redfoxes, we have conducted full analysis of all potential website Content Management Systems and Web Frameworks to provide your brand with a recommendation that fits your business needs.</p>
                        <router-link to="/contact-us" tag="button" class="btn">CONTACT US</router-link>
                    </div>        
                </div>    
            </div> 
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import '../../_Sass.scss';
.cms{
    padding-top:100px;
    padding-bottom:20px;
    .services{
        .heading{
            padding:20px 0px;
            h4{
                letter-spacing: 2px;
                text-transform: capitalize;
            }
            p{
                line-height: 2;
            }
        }
        .services-panel{
            margin: 15px 0px;
            .desc-img {
                // height:250px;
                width: 100%;
                padding:20px 0px;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
            }
        }
        
    }
    .choosing-cms{
        .cms-txt{
            margin: 20px 0px;
            h4{
                letter-spacing: 2px;
            }
            p{
                margin: 0;
                line-height: 2;
            }
        }
    }
    .why-redfoxes{
        .why{
            text-align: center;
            background-color: $lightOrange;
            padding:15px 0px;
            margin: 20px 0px;
            h4{
                letter-spacing: 2px;
            }
            p{
                padding:20px;
                margin: 0;
                line-height: 2;
            }
        }
    }
    .work-together{
        .work{
            text-align: center;
            background-color:$lightblue;
            padding:15px 0px;
            margin: 20px 0px;
            h4{
                letter-spacing: 2px;
            }
            p{
                padding:20px;
                margin: 0;
                line-height: 2;
            }
            button{
                  @include mainButton
            }
        }
    }
}
@media (max-width:426px){
    .cms{
        @include smallFontSizes
    }
}
</style>