<template>
    <div class="iot">
        <div class="container">
            <div class="row">
                <div class="col-12 ">
                    <div class="intro-head">
                        <h3>IoT Development </h3>
                        <h6>Custom IoT Solutions for End Users and Businesses</h6>
                        <p>
                            Rely on our expertise in IoT development to create consumer IoT solutions that bring convenience to your customers' homes and monitor their well-being and physical activity. We are well-versed in hardware design, embedded apps, cloud platforms, web and mobile solutions.
                        </p>
                        <p>
                            We create Smart Home solutions that optimize energy use in residential and office buildings, improve home security, enable remote climate control, and allow users to manage home appliances via voice commands. Our know-how includes:
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="content-list">
                        <h6>Smart lighting systems</h6>
                        <h6>IoT systems for HVAC</h6>
                        <h6>Alexa-controlled smart homes</h6>
                        <h6>Self-learning smart devices</h6>
                        <h6>Home security solutions: surveillance cameras, smart bells, biometric door locks</h6>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="content">
                        <video autoplay loop muted>
                            <source src="../../assets/technology2/IOT/iot.mp4" type="video/mp4">
                                Your browser does not support HTML5 video.
                        </video>
                    </div>
                </div>
            </div>
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>Smart Home Solutions</h4>
                        <p>
                            Turn to redfoxes iot solutions to create Smart Home solutions that work in sync with 3rd-party devices and apps, protect user data, keep firmware updated, support Alexa, and deliver a seamless user experience thanks to intuitive interfaces and an effortless setup process.
                        </p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Smart Lighting Solutions" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                            We build remotely controlled smart lighting systems that harvest environmental, motion, and occupancy sensor data to optimize energy use. Partner with redfoxes solutions to:
                                                        </p>
                                                    <ul>
                                                        <li>
                                                            Connect your entire suite of smart lighting products via secure protocols, including ZigBee, Z-Wave, and Thread
                                                        </li>
                                                        <li>Enable smart lighting control via web applications, mobile apps, and voice commands</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/SMART LIGHTING.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="IoT Solutions for HVAC">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Redfoxes solutions will help you transform analog heating, ventilation, and air conditioning (HVAC) systems into smart energy management and climate control solutions. We enhance thermostats, air conditioners, and heating equipment with:</p>
                                                    <ul>
                                                        <li>Intelligent direct digital control (DDL) units</li>
                                                        <li>Wireless sensors that relay data to the cloud and endpoint devices</li>
                                                        <li>Multi-camera Shoots</li>
                                                        <li>Back-end solutions, which analyze sensor data and weather conditions</li>
                                                        <li>Web, mobile, and desktop applications for managing HVAC systems</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/HVAC.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Alexa-controlled Smart Homes">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        As part of our Alexa app development services, we’ll make your Smart Home product responsive to voice commands using Alexa Voice Service (AVS) and AWS IoT Core. To enable hands-free interactions with connected home appliances, your device will:</p>
                                                    <ul>
                                                        <li>Filter background noise using noise reduction chips and algorithms</li>
                                                        <li>Distinguish homeowner voices and process voice commands with minimum latency</li>
                                                        <li>
                                                            Orchestrate Smart Home devices: connected lighting, HVAC equipment, kitchen appliances, smart TV solutions, and audio systems</li>
                                                        <li>Provide answers to searchable questions and interact with 3rd-party services via APIs</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/ALEXA.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                  <b-tab title="Self-learning Smart Home Devices">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        We’ll help you design Nest-like connected home devices that anticipate user actions based on real-time and historical data. To accomplish this goal, we’ll enhance your product with custom-trained Machine Learning models capable of:</p>
                                                    <ul>
                                                        <li>Identifying users in live video data from indoor and outdoor cameras</li>
                                                        <li>Creating operating schedules based on homeowner preferences</li>
                                                        <li>Ingesting and processing data from other devices and applications</li>
                                                        <li>Making autonomous decisions and acquiring new skills over time</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/SELFLEARNING SMART HOME DEVICES.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                  <b-tab title="Always-on Smart Home Security Solutions">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        Partner with Redfoxes to create home security system software or multi-gadget solutions featuring surveillance cameras, doorbells, and smart locks operated via a central hub. When building Smart Home security solutions, we implement:</p>
                                                    <ul>
                                                        <li>Multi-factor authentication techniques: passwords, biometrics</li>
                                                        <li>Automatic OTA firmware updates and security patches</li>
                                                        <li>Combination of local and cloud-based data storage and processing</li>
                                                        <li>Internet-independent system functioning</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/ALWAYS ON SMART HOME SECURITY.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                  <b-tab title="Baby Care IoT Solutions">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        Turn to Redfoxes IOT to design smart solutions aiding parents in baby care. Our solutions support remote baby monitoring, simplify medical care, and facilitate everyday routines for caregivers. In our portfolio, we have: </p>
                                                    <ul>
                                                        <li>
                                                            A smart bassinet with embedded sound sensors, that recognizes when a baby is crying and automatically chooses the appropriate soothing level necessary to calm them</li>
                                                        <li>Connected medical devices: non-contact thermometers, nebulizers, etc.</li>
                                                        <li>A smart baby formula</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/BABY CARE IOT.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row services" id="hardware">
                <div class="col-12">
                    <div class="heading">
                        <h4>Full-stack Smart Home Development Services</h4>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Hardware Design" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        From PCB to custom enclosure design and hardware testing, we offer comprehensive Smart Home development services to startups working on a novel device from the ground up.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/HARDWARE DESIGN.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Embedded Systems">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        We create firmware and middleware solutions for Home Automation systems to enable their business logic and connect them to the cloud, mobile apps, controllers, and other devices.
                                                        </p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/embedded systems.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Smart Home App Development">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        We design mobile and web applications that help users monitor, manage, and configure their smart gadgets — while at home or remotely.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/SMART HOME APP.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                  <b-tab title="Machine Learning Expertise">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        Being well-versed in intelligent image/video analysis and natural language processing (NLP), we design Smart Home solutions that function well with little to no human supervision.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/MACHINE LEARNING.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row how-different">
                <div class="col-12 custom-bg">
                    <h4>Staying in the Home Automation Loop</h4>
                    <p>We invest in R&amp;D and learn the latest Home Automation development tools inside out. The approach allows us to build competitive connected home products and solve practical Smart Home development challenges, such as adding voice assistants to low-memory devices and interfacing gadgets that use different connectivity technologies.</p>
                </div>
            </div>
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>Wearable Device and App Development Services</h4>
                        <p>
                            We will help you design a custom wearable device, support its business logic with a scalable back end, and pair the solution with a mobile app to enable convenient user-to-device interactions. Redfoxes IOT will also interface your wearable gadget with 3rd-party devices and applications — in the workplace or in a Smart Home.
                        </p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Wearable Gadget Design">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        Redfoxes IOT helps consumer electronics companies balance a wearable gadget’s computing power, desired functionality, and usability. Our services include:
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            PCB layout and design
                                                        </li>
                                                        <li>PCB signal, power integrity, and thermal analysis</li>
                                                        <li>Ergonomic enclosure design</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/WEAEABLE GADGET.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Embedded Software">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Our embedded engineers create software that allows wearables to collect data, transfer it to the cloud, and interact with other devices:</p>
                                                    <ul>
                                                        <li>Custom firmware: bare metal, BSP, DSP, Linux Kernel, AOSP</li>
                                                        <li>Middleware: ML-based, voice assistants, drivers, SDKs</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/EMBEDDED SOFTWARE.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Back-end Infrastructure" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        To store, process, and relay sensor data to endpoint devices and user apps, wearable gadgets need a reliable back end. We will help you with:</p>
                                                    <ul>
                                                        <li>Cloud-based data storage and processing: AWS, Microsoft Azure, Google Cloud</li>
                                                        <li>OTA firmware updates</li>
                                                        <li>Web dashboard development</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/BACKEND INFRASTRUCTURE.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                  <b-tab title="Mobile Applications">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        Our wearable technology developers will sync your custom device with a mobile app via APIs and secure connectivity protocols. This provides an opportunity to:</p>
                                                    <ul>
                                                        <li>Partially shift CPU load to a smartphone</li>
                                                        <li>Visualize sensor data on a larger screen</li>
                                                        <li>Manage the device in a convenient manner</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/MOBILE APP.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>Industry Experience</h4>
                        <p>We help hardware manufacturers and wearable technology startups design novel devices or augment wearable gadgets with custom software:</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Analog to Digital">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        Consider turning an existing device into an IoT solution? We’ll enhance the gadget with sensors, connect it to the Internet, and build an entire software ecosystem to implement it at scale.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/DIGITAL TO ANALOG.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="App Development" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        As a full-stack development services provider, we can augment your device with embedded software, mobile applications (iOS, Android, Xamarin, Flutter), and admin dashboards.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/APP DEVELOPMENT.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Turn-key Projects">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        Our turn-key electronic design services include hardware and custom software development, and assistance with compliance (HIPAA, FDA, GMP, DICOM, HL7) and mass production.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/TURNKEY PROJECTS.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                  <b-tab title="Project Rescue">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>
                                                        From PCB failure diagnostics to cybersecurity and DevOps implementation, we offer a wide range of services to help companies jump-start stalled projects and launch new products ahead of the competition.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/IOT/PROJECT RESCUE.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row digital-solutions">
                <div class="col-12">
                    <div class="digial-heading">
                        <h4>Digital Solutions for the Healthcare Industry</h4>
                        <p>
                            We help health facilities overhaul legacy IT systems, automate medical image analysis, and monitor patients’ well-being in and out of the hospital. Redfoxes also provides custom healthcare software development services to technology startups working on medical devices — vital signs monitors, insulin pumps, or diagnostic equipment.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="digital-list">
                        <h4>Why Go Digital?</h4>
                        <p>
                            Digital healthcare solutions allow hospitals to reduce paperwork, serve more patients without adding staff or beds, and access medical data stored in disparate IT systems via a single user-friendly interface. </p>
                        <p>
                            These days, patients assume that they will use technology to interact with healthcare providers. They expect digital health solutions to be modern, convenient, and consistent with their experience in highly digitalized industries, such as banking and eCommerce:</p>
                        <div class="row">
                            <div class="col-lg-3 col-6 mt-4">
                                <div class="item">
                                    <h6>PRESCRIPTION REFILLS</h6>
                                    <p>79% of patients are willing to refill medications using an app or website (source: McKinsey)</p>
                                </div>
                            </div> 
                            <div class="col-lg-3 col-6 mt-4">
                                <div class="item">
                                    <h6>HEALTH DATA MANAGEMENT</h6>
                                    <p>71% of patients would like to check personal health information online (source: McKinsey)</p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-6 mt-4">
                                <div class="item">
                                    <h6>REMOTE PATIENT CARE</h6>
                                    <p>
                                        60% of US patients aged 65+ do not mind managing chronic conditions via telecare visits (source: Healthcare IT News
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-6 mt-4">
                                <div class="item">
                                    <h6>TELEMEDICINE FOLLOW-UPS</h6>
                                    <p>79% of patients prefer telecare follow-ups to in-person visits (source: Massachusetts General Hospital)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="why-redfoxes">
                        <h4>Why Redfoxes?</h4>
                        <div class="row">
                            <div class="col-lg-4 mt-4">
                                <div class="item">
                                    <h6>Data Interoperability</h6>
                                    <p>Our team will find an optimal way to connect your custom device or application to hospital, pharmacy, and health insurance software.</p>
                                </div>
                            </div>
                            <div class="col-lg-4  mt-4">
                                <div class="item">
                                    <h6>Security</h6>
                                    <p>We run manual and automated tests (hardware, firmware, web, mobile) to make sure your healthcare technology system is resistant to hacking and protects patient data.</p>
                                </div>
                            </div>
                            <div class="col-lg-4  mt-4">
                                <div class="item">
                                    <h6>Compliance</h6>
                                    <p>We build software and hardware solutions that meet industry-specific standards and regulations: HIPAA, HL7, GMP, DICOM, and FDA among others.</p>
                                </div>
                            </div>
                            <div class="col-md-6 mt-4">
                                <div class="item">
                                    <h6>From Sensor Data to Smart Insights</h6>
                                    <p>As a company that specializes in IoT solutions, we can help you collect and analyze the data coming from an array of wearable sensors, vital sign monitors, point of care (POC) equipment, and medication dispensers connected to the Internet, hospital software, and each other.</p>
                                </div>
                            </div>
                            <div class="col-md-6 mt-4">
                                <div class="item">
                                    <h6>Transforming Healthcare Reality</h6>
                                    <p>We can assist you in building and fine-tuning the performance of AR-based remote guidance systems for operating theatres and medical schools. Redfoxes can also create an Augmented Reality mobile app serving as a user interface and manual for complex medical equipment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row work-together">
                <div class="col-12">
                    <div class="talk">
                        <h4>Let’s Talk Technology</h4>
                        <h6>Stay up to date on all that is technology, and what's happening in all of our digital endeavors</h6>
                        <p>We're passionate about innovation, brilliant ideas and the execution that brings it all together in one beautiful experience. If you are too, call or send us an email to get started. </p>
                        <router-link to="/contact-us" tag="button" class="btn">CONTACT US</router-link>
                    </div>        
                </div>    
            </div> 
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import'../../_Sass.scss';
.iot{
    padding-top:100px;
    padding-bottom:20px;
    .intro-head{
        padding: 20px 0px;
        h3{
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
    .content-list{
        h6{
            padding:8px;
            border-radius:1px;
            margin: 10px 0px;
            letter-spacing:2px;
            border-left:3px solid $orange;
        }
    }
    .content{
        height:310px;
        width: 100%;
        img,video{
            width: 100%;
            height: 100%;
            padding: 10px;
            object-fit: cover;
        }
    }
    .services{
        .heading{
            padding:20px 0px;
            h4{
                letter-spacing: 2px;
                text-transform: capitalize;
            }
            p{
                line-height: 2;
            }
        }
        .services-panel{
            margin: 15px 0px;
            .desc-img {
                // height:250px;
                width: 100%;
                padding:20px 0px;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
            }
        }
        
    }

    .how-different{
        margin: 20px 0;
        .custom-bg{
            background-color:$lightOrange;
            text-align: center;
            height: 100%;
            width: 100%;
            padding: 15px ;
            h4{
                margin-bottom:16px;
            }
            p{
                padding:0px 10px;
                line-height: 2;
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
    .digital-solutions{
        .digial-heading,.digital-list{
            h4{margin: 10px 0px;}
            p{
                line-height: 2;
                margin: 0;

            }
        }
        .digital-list,.why-redfoxes{
            width: 100%;
            height: 100%;
            margin: 10px 0px;
            padding: 10px 0px;
            .item{
                height: 100%;
                padding: 10px;
                box-shadow: $lightblue -10px 10px;
                background-color: $lightOrange;
                h6{
                    font-size: 14px;
                }
                p{
                padding: 5px;
                margin: 0;
                font-size: 12px;
                line-height: 2;
                }
            }
        }
        .why-redfoxes{
        }
    }
    .work-together{
        .talk{
            text-align: center;
            margin:5px 0px;
            padding: 15px;
            background-color:$lightblue;
            text-align: center;
        h4{
            letter-spacing: 2px;
            padding: 10px 0px;
        }
        p{
            padding:20px;
            margin: 0;
            line-height: 2;
        }
        button{
            @include mainButton
        }
    }
    }
}
@media (max-width:426px){
    .iot{
        @include smallFontSizes
    }
}
</style>