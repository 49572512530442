<template>
    <div class="design">
        <div class="container">
            <div class="row">
                <div class="col-12 ">
                    <div class="intro-head">
                        <h3>Design</h3>
                        <p>We understand the effect a great brand story can have with its audience, but we also understand the trust and affinity that can only come with great design.</p>
                        <h6>FEATURED DESIGN PROJECTS</h6>
                        <p>We take pride in all of our designs. Everything is the smart accurate researched, planned, tested and designed to work and look flawlessly.</p>
                    </div>
                </div>
            </div>
            <div class="row design-solutions">
                <diV class="col-12">
                    <h4>DESIGN SOLUTIONS</h4>
                    <p>Our designs must not only look beautiful, but they must also function beautifully. A great design not only entices you to touch, but it must be intuitive and simple to understand.</p>
                </diV>
                <div class="col-md-6 col-12">
                    <div class="txt-container">
                        <div class="txt-content">
                            <h6>App Design</h6>
                            <p>We are here to understand your business, help develop and design a product that facilitates your business workflow and delivers an intuitively beautiful user experience that makes an impact.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 mb-3">
                    <div class="img-container">
                        <img src="../assets/design/app design.svg">
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="img-container">
                        <img src="../assets/design/web design.svg">
                    </div>
                </div>
                <div class="col-md-6 col-12 mb-3">
                    <div class="txt-container">
                        <div class="txt-content">
                            <h6>Website Design</h6>
                            <p>Our goal is to deliver an impactful, visually stunning web experience that elevates the online web presence of every brand we work with. We consistently challenge ourselves to always deliver something fresh, something new and something that gets results.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="txt-container">
                        <div class="txt-content">             
                            <h6>Traditional Design</h6>
                            <p>Redfoxes is a 360˚ creative agency and our designs are not limited to digital screens. We can deliver a fully integrated campaigns that leverage magazines, newspapers, posters and billboards.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 mb-3">
                    <div class="img-container">
                        <img src="../assets/design/traditinoal design.svg">
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="img-container">
                        <img src="../assets/design/ex designing.svg">
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="txt-container">
                        <div class="txt-content">             
                            <h6>Experience Design</h6>
                            <p>Many times we are tasked to bring the digital into the physical world. Our team of experienced designers embrace the challenge. Whether its out of home, physical/digital experiences and events, signage or sculptures, we handle it all.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>DESIGN SERVICES</h4>
                        <p>Every design project at redfoxes starts with a thorough analysis. We want to identify each brands needs, direction, its aspirations and goals. We then align those goals to help inspire and interact with our audience. Our end goal is a unique design that is catered to each brand.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Research &amp; Discovery" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Every design project begins with a thorough research and discovery phase where we gather all the necessary details about your company, its target market, challenges, expected end goals and more.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/design/RESREARCH AND DISCOVERY.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="User Experience Design">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>After some research and discovery, our next step is to align our findings with proven design strategies, organize our content and story into layouts and modules streamlined for conversion.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/design/UX.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="User Interface Design">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>We continue our design process through user interface design, where we explore interfaces, color, icons and font treatments to streamline navigation, establish a simple and intuitive content hierarchy and enhance the overall brand aesthetic.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/design/UI.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                  <b-tab title="Art Direction">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                              <div>
                                                  <p>Visual storytelling is key to setting the overall aesthetic of the brand, it also sets the mood and feel of any digital, print or live experience. Our art directors help bring brands and experiences to life through the use of photography, video, color, and typeface treatment.</p>
                                              </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/design/ART DIRECTION.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                  <b-tab title="Production">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Working with the right design partner is not everything; you also need a production partner that has the know-how to deliver an efficient, cost-productive, high-quality final product, experience, and or overall campaign.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/design/PRODUCTION.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                  <b-tab title="Testing and Optimization">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>To ensure everything we design is optimized to give the best results, we fully embrace the design, test, optimize and repeat process to ensure we are delivering the best results for our clients.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/design/TESTING.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row work-together">
                <div class="col-12">
                    <div class="talk">
                        <h4>Let’s design your next project</h4>
                        <p>We're passionate about design, innovation, brilliant ideas and the execution that brings it all together in one beautiful experience. If you are too, call or send us an email to get started.</p>
                        <router-link to="/contact-us" tag="button" class="btn">CONTACT US</router-link>
                    </div>        
                </div>    
            </div> 
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.design{
    padding-top:130px;
    padding-bottom:50px;
    background-color: white;
    color: black;
    overflow:hidden;
    p{
            line-height: 2;
    }
    .intro-head{
        padding: 20px 0px;
        h3{
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
    .design-solutions{
        h4{
                letter-spacing: 2px;
                text-transform:uppercase;
        }
        .txt-container{
            display: flex;
            align-items: center;
            height: 100%;
            .txt-content{
                padding:0px 10px;
                border-left:3px solid $orange;
                p{
                    margin: 0;
                    padding: 10px;
                    line-height: 2;
                    background-color:$lightblue;
                }
            }
        }
        .img-container{
            width: 100%;
            height: 200px;
            img{
                width: 100%;
                height: 100%;
                padding:20px 0px;
            }
        }
    }
    .services{
        .heading{
            padding:20px 0px;
            h4{
                letter-spacing: 2px;
                text-transform: capitalize;
            }
            p{
                line-height: 2;
            }
        }
        .services-panel{
            margin: 15px 0px;
            .desc-img {
                // height:250px;
                padding:20px 0px;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                    padding: 10px;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
                button{
                    @include mainButton
                }
            }
        }
    }
    .work-together{
        .talk{
            text-align: center;
            margin: 20px 0px;
            background-color:$lightblue;
            padding:15px 0px;
            text-align: center;
        h4{
            letter-spacing: 2px;
        }
        p{
            padding:20px;
            margin: 0;
            line-height: 2;
        }
        button{
            @include mainButton
        }
    }
    }
}
@media (max-width:426px){
    .design{
         @include smallFontSizes
    }
}
</style>