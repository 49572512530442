<template>
    <div class="marketing">
        <div class="container">
            <div class="row">
                <div class="col-12 ">
                    <div class="intro-head">
                        <h3>Marketing Tools</h3>
                        <p>We take pride in delivering and servicing fully-integrated, proven digital marketing solutions. As your partner digital agency in middle east, we are here to help support all your brands' digital needs.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="content-list">
                        <h6>Banner Ads &amp; Retargeting</h6>
                        <h6>Print Campaigns</h6>
                        <h6>CRM</h6>
                        <h6>Email Campaigns</h6>
                        <h6>Outdoor</h6>
                        <h6>Social Media Campaigns</h6>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="content">
                        <video autoplay loop muted>
                            <source src="../assets/marketing/Communication - 19498.mp4" type="video/mp4">
                            Your browser does not support HTML5 video.
                        </video>
                    </div>
                </div>
            </div>
            <div class="row working-with">
                <div class="col-md-4">
                    <div class="img">
                        <img src="../assets/LOGO/MARKETING AGENCY.png">
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="txt">
                        <p>
                            "
                            RedFoxes is an independent Digital Marketing Agency in the heart of Egypt, we are committed to offer 360˚ digital marketing services focussed on getting results that drive you to attain a reputed market position and achieve many more milestones
                            "
                        </p>
                    </div>
                </div>
            </div>
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>DIGITAL MARKETING SERVICES</h4>
                        <h6>Your 360˚ Marketing Partner</h6>
                        <p>We take pride in delivering and servicing fully-integrated, proven digital marketing solutions. As your partner digital marketing agency in middle east, we are here to help support all your brands digital needs.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Marketing Strategy" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Digitization of business is changing the way brands attract leads, engage prospects, create conversions and compete with each other. A good digital strategy, backed by real-time data can help organizations in everything from lead management to customer retention.</p>
                                                    <router-link to="/strategy" tag="button" class="btn">Read More ...</router-link>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Markting Tools/MARKET STRATEGY.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Content Marketing">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Quality content is the defining factor that differentiates between a good brand and a great one. Creating content which can reel in superior-quality and verifiable leads is extremely important. Redfoxes prides itself on its ability to curate and create highly-engaging and effective content. </p>
                                                    <router-link to="/content" tag="button" class="btn">Read More ...</router-link>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Markting Tools/CONTENT MARKETING.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Lead Generation">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>At RedFoxes, we specialize in prospecting and lead generation. Using the latest software, we will take care of end-to-end lead generation for you. From optimized landing page strategies to state of the art tracking data, we can configure and optimize a successful lead generation strategy.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Markting Tools/LEAD GENERATION.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="SEO &amp; Search Marketing">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                              <div>
                                                  <p>Having an online presence was the first step to expanding your services online, now you need to make sure your investment is optimized to get the search results you deserve. Our SEO and Search Marketing services are catered to deliver results, no matter the type of business/services your provide. </p>
                                                  <router-link to="/seo" tag="button" class="btn">Read More ...</router-link>
                                              </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Markting Tools/SEO.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Social Marketing">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Our social media marketing team is here to walk you through tried strategies that get results. Our strategies get your brand noticed, shared, talked about, liked and hearted, no matter the social network, we have the know-how to get you the results your brand requires. </p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Markting Tools/SOCIAL MARKETING.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Marketing Campaigns">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>From digital banner ads to digital concierge experiences our team of digital marketing experts are here to develop full blown 360˚ marketing campaigns that attracts visitors and convert audiences into loyal advocates</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Markting Tools/MARKETING CAMPAIGN.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row work-together">
                <div class="col-12">
                    <div class="talk">
                        <h4>Let’s talk together</h4>
                        <p>We're passionate about innovation, brilliant ideas and the execution that brings it all together in one beautiful experience. If you are too, call or send us an email to get started.</p>
                        <router-link to="/contact-us" tag="button" class="btn">CONTACT US</router-link>
                    </div>        
                </div>    
            </div> 
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.marketing{
    padding-top:130px;
    padding-bottom:50px;
    background-color: white;
    color: black;
    overflow:hidden;
    p{
            line-height: 2;
    }
    .intro-head{
        padding: 20px 0px;
        h3{
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
    .content-list{
        h6{
            padding:8px;
            border-radius:1px;
            margin: 10px 0px;
            letter-spacing:2px;
            border-left:3px solid $orange;
        }
    }
    .content{
        height:310px;
        width: 100%;
        img,video{
            width: 100%;
            height: 100%;
            padding: 10px;
            object-fit: cover;
        }
    }
    .content-marketing{
        padding: 20px 0px;
        margin: 20px 0px;
        display: flex;
        align-items: center;
        background-color:rgba(248, 249, 250, 0.881);
        .img{
            height: 250px;
            img{
                width: 100%;
                height:100%;
                padding: 10px 0px;
            }
        }
        p{
            line-height: 2;
            margin: 0;
            text-align: center;
        }
    }
    .working-with{
        // padding: 20px 0px;
        margin: 20px 0px;
        display: flex;
        align-items: center;
        background-color:$lightblue;
        .img{
            display: flex;
            justify-content: center;
            // text-align: center;
            img{
                width: 230px;
                height: 230px;
                padding: 10px 0px;
            }
        }
        p{
            line-height:3;
            margin: 0;
            text-align: center;
            padding: 15px;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .services{
        .heading{
            padding:20px 0px;
            h4{
                letter-spacing: 2px;
                text-transform: capitalize;
            }
            p{
                line-height: 2;
            }
        }
        .services-panel{
            margin: 15px 0px;
            .desc-img {
                // height:250px;
                padding:20px 0px;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                    padding: 10px;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
                button{
                    @include mainButton
                }
            }
        }
    }
    .work-together{
        .talk{
            text-align: center;
            margin: 20px 0px;
            background-color:$lightblue;
            padding:15px 0px;
            text-align: center;
        h4{
            letter-spacing: 2px;
        }
        p{
            padding:20px;
            margin: 0;
            line-height: 2;
        }
        button{
            @include mainButton
        }
    }
    }
}
@media (max-width:425px){
    .marketing{
         @include smallFontSizes
    }
}
</style>