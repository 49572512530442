<template>
    <div class="video">
        <div class="container">
            <div class="row">
                <div class="col-12 ">
                    <div class="intro-head">
                        <h3>Video Production</h3>
                        <p>With a creative team heavily experienced in digital video production services and digital video animation, we created redfoxes Films to take our branding and campaign launches to a whole new level for the benefit of our business clients.</p>
                        <h6>Visual Storytelling</h6>
                        <p>Digital storytelling is an integral part for establishing a brand visual. It is still the most engaging content a brand can leverage to connect with its audience. A well redfoxes video conveys the brand message whilst gripping their attention in the digital narration.</p>
                        <p><mark>Do you feel the itch to promote your brand with exciting visuals or animation?</mark> Then hire a professional video production company in middle east.</p>
                    </div>
                </div>
            </div>
            <div class="row video-content">
                <diV class="col-12">
                    <h4>VIDEO CONTENT</h4>
                    <h6>Lights, Camera, Action!</h6>
                    <p>In this digital age it's all about content and video is still leading in engagement. Our team has the experience to bring to life any kinds of video, whether it's an interview style, how-to, anthem or tv commercial.</p>
                </diV>
                <div class="col-md-6 mb-3">
                    <div class="txt-container">
                        <h6>Creative Video Content</h6>
                        <p>Whether you’re an up-and-coming startup company or a well-established household name brand, we produce rich video content for every size business in the connected age. Brand videos, marketing videos, product video demos, video teasers and sizzle reels are just a few to name.</p>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="img-container">
                        <video autoplay loop muted>
                            <source src="../assets\marketing\Content/brand storytelling.mp4" type="video/mp4">
                                Your browser does not support HTML5 video.
                        </video>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="img-container">
                        <video autoplay loop muted>
                            <source src="../assets\marketing\Content/creative video content.mp4" type="video/mp4">
                                Your browser does not support HTML5 video.
                        </video>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="txt-container">
                        <h6>Brand Storytelling</h6>
                        <p>As a veteran creative team with professional Television commercials and various broadcast films, we feel right at home on the film set and in the video editing suite. When it comes to high-quality video content, we think it's safe to say we're more than qualified.</p>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="txt-container">
                        <h6>How to Demo Videos</h6>
                        <p>Got an awesome product out there, a clever, new digital platform or just a breakthrough start-up idea? Well, we can help bring it to life and convey its message to the world through a fun, sophisticated approach across all screens, devices and social media worlds.</p>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="img-container">
                        <img src="../assets\marketing\Content/Product-demo-video.png">
                    </div>
                </div>
            </div>
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>VIDEO PRODUCTION SERVICES</h4>
                        <h6>Behind Every Production</h6>
                        <p>Here are some of the services we provide to bring your brand-story to life. From Concept Development to Delivery we guide you through the process and ensure you play a key role throughout every stage of the video production process.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Creative" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-md-6">
                                                <div>
                                                    <ul>
                                                        <li>Art Direction</li>
                                                        <li>Concept Development</li>
                                                        <li>Costume Design</li>
                                                        <li>Makeup and Hair</li>
                                                        <li>Scriptwriting</li>
                                                        <li>Set Design &amp; Construction</li>
                                                        <li>Story-boarding</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-md-6">
                                                <video autoplay loop muted>
                                                    <source src="../assets\marketing\Content/video production services/creative.mp4" type="video/mp4">
                                                        Your browser does not support HTML5 video.
                                                </video>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Filming">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-md-6">
                                                <div>
                                                    <ul>
                                                        <li>Drone Cinematography</li>
                                                        <li>Green-screen and Studio Shoots</li>
                                                        <li>Multi-camera Shoots</li>
                                                        <li>Location Shoots</li>
                                                        <li>Time-lapse Video</li>
                                                        <li>Underwater Filming</li>
                                                        <li>Live Event Coverage</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="desc-img col-md-6">
                                                <video autoplay loop muted>
                                                    <source src="../assets\marketing\Content/video production services/filming.mp4" type="video/mp4">
                                                        Your browser does not support HTML5 video.
                                                </video>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Video Production ">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-md-6">
                                                <ul>
                                                    <li>Casting</li>
                                                    <li>Cinematography</li>
                                                    <li>Location Scouting</li>
                                                    <li>Logistics &amp; Scheduling</li>
                                                    <li>Production Crew</li>
                                                    <li>Lighting</li>
                                                    <li>Sound</li>
                                                </ul>
                                            </div>
                                            <div class="desc-img col-md-6">
                                                <img src="../assets\marketing\Content/video production services/video production png.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                  <b-tab title="Post-production ">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-md-6">
                                                <ul>
                                                    <li>Animations</li>
                                                    <li>Audio Mixing</li>
                                                    <li>Color Grading</li>
                                                    <li>Composition &amp; Sound Design</li>
                                                    <li>Soundtrack &amp; Scoring</li>
                                                    <li>Soundtrack &amp; Scoring</li>
                                                    <li>3D</li>
                                                    <li>Translations</li>
                                                    <li>Video Editing</li>
                                                    <li>Voiceover</li>
                                                </ul>
                                            </div>
                                            <div class="desc-img col-md-6">
                                                <video autoplay loop muted>
                                                    <source src="../assets\marketing\Content/video production services/post production .mp4" type="video/mp4">
                                                        Your browser does not support HTML5 video.
                                                </video>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row how-different">
                <div class="col-12 custom-bg">
                    <h4>How We’re Different</h4>
                    <p>We know that choosing a video production company can be an exhaustive process. There are already so many video production houses and directors in the marketplace, and it’s only becoming more and more saturated every day.</p>
                    <p>. At Redfoxes, we try to make this decision as easy as possible for the client. Our work speaks for itself and our passion shows through the high level of story telling that goes into every film we produce.</p>
                </div>
            </div>
            <div class="row work-together">
                <div class="col-12 talk">
                        <h4>Let’s talk video</h4>
                        <p>At Redfoxes we love the designing of storytelling, and film remains the most engaging form of communication and entertainment, but we also feel when its is expertly infused with technology, the medium transforms into a dynamic interactive and engaging experience. If you are ready to tell your brand story, our video production team is here to help you capture it. Give us a call today.</p>
                        <router-link to="/contact-us" tag="button" class="btn">CONTACT US</router-link>   
                </div>    
            </div> 
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.video{
    padding-top:100px;
    padding-bottom:20px;
    mark{
    background-color: $lightOrange;
    }
    .intro-head{
        padding: 20px 0px;
        h3{
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        p{
            line-height: 2;
        }
    }
    .video-content{
        h4{
                letter-spacing: 2px;
                text-transform:uppercase;
        }
        .txt-container{
            // height: 100%;
                p{
                    background-color:$lightblue;
                    border-left:3px solid $orange;
                    margin: 0;
                    padding: 10px;
                    line-height: 2;
                }
        }
        .img-container{
            width: 100%;
            height: 200px;
            img,video{
                width: 100%;
                height: 100%;
                // padding:20px 0px;
                object-fit: cover;
            }
        }
    }
    .content{
        height:310px;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            padding: 10px;
        }
    }
    .services{
        .heading{
            padding:20px 0px;
            h4{
                letter-spacing: 2px;
                text-transform:uppercase;
            }
            p{
                line-height: 2;
            }
        }
        .services-panel{
            margin: 15px 0px;
            .desc-img {
                height:350px;
                // text-align: center;
                img,video{
                    height: 100%;
                    width: 100%;
                    padding:20px;
                    // object-fit: cover;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                ul{
                    padding: 0;
                    li{
                        list-style: none;
                        padding:5px 10px;
                        border-radius:1px;
                        margin: 10px 0px;
                        letter-spacing:2px;
                        border-left:3px solid $orange;
                    }
                }
            }
        }
        
    }
    .how-different{
        margin: 20px 0;
        .custom-bg{
            background-color:$lightOrange;
            text-align: center;
            height: 100%;
            width: 100%;
            padding: 15px ;
            h4{
                margin-bottom:16px;
            }
            p{
                padding:0px 10px;
                line-height: 2;
            }
        }
    }
    .work-together{
        margin: 20px 0px;
        .talk{
            text-align: center;
            height: 100%;
            width: 100%;
            background-color:$lightblue;
            padding: 15px;
            text-align: center;
        h4{
            letter-spacing: 2px;
            padding: 10px 0px;
        }
        p{
            padding:15px;
            margin: 0;
            line-height: 2;
        }
        button{
            @include mainButton
        }
    }
    }
}
@media (max-width:426px){
    .video{
        @include smallFontSizes
    }
}
</style>