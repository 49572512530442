<template>
    <div class="e-commerce">
        <div class="container">
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>E-COMMERCE DEVELOPMENT</h4>
                        <h6>Gear up for an impactful e-commerce journey</h6>
                        <p>Your e-commerce store is your gateway to profitability. Partner with us for a stunning, responsive, and easy-to-use e-commerce store. Our web developers can design &amp; work on</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Shopify Development" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-6">
                                                <div>
                                                    <p>Launch a brand new Shopify store and see a visible increase in site visits and purchases. Our Shopify web development services are designed to help you maximize your reach and functionality. Display your products in the best light and create a hassle-free purchase experience.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-6">
                                                <img src="../../assets/technology2/E-Commerce/SHOPIFY.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Big Commerce Development">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-6">
                                                <div>
                                                    <p>Have very specific requirements for your web store? If yes, choose us for Big commerce web development. With extremely powerful plugins and integrations, you can set the stage for an amazing customer journey. Its minimal coding needs ensures that you don’t struggle with maintaining the store as time passes.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-6">
                                                <img src="../../assets/technology2/E-Commerce/BIG COMMERCE DEVELOPMENT.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Magento Development">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-6">
                                                <p>our web developers specialize in Magneto web development. Overcome problems of high traffic website breakdown and low-speed page loads to create a stellar e-commerce store that is extremely robust and responsive.</p>
                                            </div>
                                            <div class="desc-img col-6">
                                                <img src="../../assets/technology2/E-Commerce/CMS MAGENTO.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Woo Commerce Development">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-6">
                                                <p>Create an interactive and intuitive website that your site visitors will really enjoy, with the help of our Woo Commerce web design &amp; development service. We integrate numerous widgets and plugins that can help you generate leads, engage visitors, and leverage the power of analytics.</p>
                                            </div>
                                            <div class="desc-img col-6">
                                                <img src="../../assets/technology2/E-Commerce/WOO.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row work-together">
                <div class="col-12">
                    <div class="work">
                        <h4>Let’s work together</h4>
                        <p>Here at redfoxes, we have conducted full analysis of all potential website Content Management Systems and Web Frameworks to provide your brand with a recommendation that fits your business needs.</p>
                        <router-link to="/contact-us" tag="button" class="btn">CONTACT US</router-link>
                    </div>        
                </div>    
            </div> 
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import '../../_Sass.scss';
.e-commerce{
    padding-top:100px;
    padding-bottom:20px;
    .services{
        .heading{
            padding:20px 0px;
            h4{
                letter-spacing: 2px;
                text-transform: capitalize;
            }
            p{
                line-height: 2;
            }
        }
        .services-panel{
            margin: 15px 0px;
            .desc-img {
                // height:250px;
                width: 100%;
                padding:20px 0px;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
            }
        }
        
    }
    .work-together{
        .work{
            text-align: center;
            background-color:$lightblue;
            padding:15px 0px;
            margin: 20px 0px;
            h4{
                letter-spacing: 2px;
            }
            p{
                padding:20px;
                margin: 0;
                line-height: 2;
            }
            button{
                @include mainButton
            }
        }
    }
}

@media (max-width:426px){
    .e-commerce{
        @include smallFontSizes
    }
}
</style>