import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ContactUs from "../views/ContactUs.vue";
import Career from "../views/Career.vue";
import Products from "../views/Products.vue";
import Design from'../views/Design.vue';
import Branding from '../ServicesComponents/Branding.vue'
import Content from'../ServicesComponents/Content.vue'
import Video from '../ServicesComponents/Video.vue'
import Strategy from '../ServicesComponents/Strategy.vue'
import Marketing from '../ServicesComponents/Marketing.vue'
import Seo from '../ServicesComponents/Seo.vue'
import Technology from '../ServicesComponents/Technology.vue'
import Cms from '../ServicesComponents/TechnologyComponents/Cms.vue'
import Web from'../ServicesComponents/TechnologyComponents/Web.vue'
import ECommerce from '../ServicesComponents/TechnologyComponents/ECommerce.vue'
import Mobile from '../ServicesComponents/TechnologyComponents/Mobile.vue'
import Iot from '../ServicesComponents/TechnologyComponents/Iot.vue'
//////////////////////////////////////////////////////////////////////
import CommingSoon from '../views/CommingSoon.vue'
//////////////////////////////////////////////////////////////////////
import Diabetes from '../blog/Diabetes.vue'
import BlueShift from '../blog/UVC.vue'
Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    alias:"/",
    name: "home",
    component: Home,
  },
  {
    path: "/products",
    name: "products",
    component:Products
  },
  {
    path: "/career",
    name: "career",
    component:Career
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: ContactUs
  },
  {
    path: "/design",
    name:"design",
    component:Design
  },
  {
    path:'/branding',
    name:"branding",
    component:Branding
  },
  {
    path:'/content',
    name:"content",
    component:Content
  },
  {
    path:'/video',
    name:"video",
    component:Video,
  },
  {
    path:'/strategy',
    name:"strategy",
    component:Strategy
  },
  {
    path:'/marketing',
    name:"marketing",
    component:Marketing
  },
  {
    path:'/seo',
    name:"seo",
    component:Seo,
  },
  {
    path:'/technology',
    name:"technology",
    component:Technology
  },
  {
    path:'/web-solutions',
    name:"webSolutions",
    component:Web
  },
  {
    path:'/mobile-solutions',
    name:"mobileSolutions",
    component:Mobile
  },
  {
    path:'/cms-solutions',
    name:"cmsSolutions",
    component:Cms
  },
  {
    path:'/eCommerce-solutions',
    name:"eCommerceSolutions",
    component:ECommerce
  },
  {
    path:'/iot',
    name:"iot",
    component:Iot
  },
  {
    path:'/comming-soon',
    name:"commingSoon",
    component:CommingSoon
  },
  // blog pages
  {
    path:'/diabetes-device',
    name:'diabetes',
    component:Diabetes
  },
  {
    path:'/blue-shift-device',
    name:'blueShift',
    component:BlueShift
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // config the user scroll behavior to last place he comes from or return to the top of the page (default config)
  scrollBehavior(to ,from,savedPosition){
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    if (savedPosition){
      return savedPosition
    }
    return {left:0,top:0}
  }
});

export default router;
