<template>
  <div class="footer" :class="{'custom-footer':custom=='/home' || custom=='/'}">
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-3 services">
            <div class="services-list">
              <h4>Services</h4>
              <ul>
                <li><router-link  :to="{name: 'marketing'}">Marketing</router-link></li>
                <li><router-link  :to="{name: 'content'}">Content</router-link></li>
                <li><router-link  :to="{name: 'branding'}">Branding</router-link></li>
                <li><router-link  :to="{name: 'strategy'}">Strategy</router-link></li>
                <li><router-link  :to="{name: 'seo'}">SEO</router-link></li>
                <li><router-link  :to="{name: 'video'}">Video Production</router-link></li>
                <li><router-link  :to="{name: 'technology'}">Technology</router-link></li>
                <li><router-link  :to="{name: 'iot'}">IOT</router-link></li>
                <li><router-link  :to="{name: 'cmsSolutions'}">CMS Development</router-link></li>
                <li><router-link  :to="{name: 'eCommerceSolutions'}">E-commerce Development</router-link></li>
                <li><router-link  :to="{name: 'webSolutions'}">Web Development</router-link></li>
                <li><router-link  :to="{name: 'mobileSolutions'}">Mobile Development</router-link></li>
                <li><router-link  :to="{name: 'home'}">Home</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 services">
            <div class="services-list">
              <h4>Industries</h4>
              <ul>
                <li><router-link  :to="{name: 'commingSoon'}">Beauty &amp; Fashion</router-link></li>
                <li><router-link  :to="{name: 'commingSoon'}">Food &amp; Beverage</router-link></li>
                <li><router-link  :to="{name: 'commingSoon'}">Media &amp; Publications</router-link></li>
                <li><router-link  :to="{name: 'commingSoon'}">Real Estate</router-link></li>
                <li><router-link  :to="{name: 'commingSoon'}">Sports &amp; Entertainment</router-link></li>
                <li><router-link  :to="{name: 'commingSoon'}">Startups</router-link></li>
                <li><router-link  :to="{name: 'home'}">Home</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 services">
            <div class="services-list">
              <h4>Focus</h4>
              <ul>
                <li><router-link  :to="{name: 'mobileSolutions'}">App Design</router-link></li>
                <li><router-link  :to="{name: 'mobileSolutions'}">App Development</router-link></li>
                <li><router-link  :to="{name: 'seo'}">SEO</router-link></li>
                <li><router-link  :to="{name: 'video'}">Video Production</router-link></li>
                <li><router-link  :to="{name: 'webSolutions'}">Website Design</router-link></li>
                <li><router-link  :to="{name: 'webSolutions'}">Web Development</router-link></li>
                <li><router-link  :to="{name: 'home'}">Home</router-link></li>
              </ul>
            </div>
          </div>

          <div class="contact-us col-md-3">
            <div>
              <router-link tag="h4" to="/contact-us" class="custom-h4">Contact Us</router-link>
              <div>
                  <h6>Address :</h6>
                  <p>Benha, Qalyubia Governorate, Egypt</p>
              </div>
              <div>
                <h6>Phone :</h6>
                <p>002 013 3362227</p>
              </div>
              <div>
                <h6>E-mail :</h6>
                <p>info@redfoxesagency.com</p>
              </div>
            </div>
          <div class="find-us">
            <h6>Find US On</h6>
            <SocialNetworks></SocialNetworks>
          </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <div class="container">
          <h6>Copyright 2022 @ RedFoxes All Rights Reserved</h6>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import SocialNetworks from './SocialNetworks.vue'
export default {
  name: "Footer",
  components:{
    SocialNetworks
  },
  data(){
    return{
      custom:this.$route.path,
    }
  },
  watch:{
    $route(){
      this.custom=this.$route.path
      // console.log(this.custom)
    }
  },
};
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.footer{
      background-color:$lightOrange;
      color: $black;
      width: 100%;
      h4 {
      text-transform: uppercase;
      letter-spacing: 2px;
      &:hover{
        color:$orange;
      }
    }
  .services{
      padding: 15px;
      .services-list{
        width: 100%;
        height: 100%;
        ul{
          padding: 0;
          margin: 0;
          li{
            list-style: none;
            margin: 5px 0px;
            a{
              color: $black;
              letter-spacing: 2px;
              padding:5px;
              &:hover{
                color:$orange;;
              }
            }
          }
        }
      }
  }
  .contact-us {
    padding: 15px;
    .custom-h4{
      cursor: pointer;
    }
      word-break: break-word;
      h6{
      margin-bottom:5px;
      padding: 5px 0px;
      &:last-child{margin-bottom:10px;}
    }
  }
  .copyright {
    background-color: rgba($orange,0.1);
    padding: 10px 0px;
    h6 {
      margin: 0px;
      text-align: center;
      font-size: 12px;
    }
  }
}
.custom-footer{
  width: 100%;
  position: absolute;
  z-index: 2;
  background-color:rgba($black,0.75);
  .services{
      color: white;
      .services-list{
        ul{
          li{
            a{
              color: white;
            }
          }
        }
      }
  }
  .contact-us {
    color: white;
  }
  .copyright{
    background-color: rgba($black,0.75);
    h6{color: white;}
  }
}
</style>
