import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCB4_DrDfsg4B8Jg4qcRrUFsNjnKcduBVI",
  authDomain: "test-74d31.firebaseapp.com",
  projectId: "test-74d31",
  storageBucket: "test-74d31.appspot.com",
  messagingSenderId: "620564097791",
  appId: "1:620564097791:web:8eee3b613303b9e9e5baca",
  measurementId: "G-9KEQJSQHZD"
};

  // init firebase app
  firebase.initializeApp(firebaseConfig)

  // init firestore
  const db = firebase.firestore()
  const storage=firebase.storage()
  export { db,storage }