<template>
  <div class="top-button hidden-sm">
    <span @click="topFunction" id="top-btn" title="Go to top"><i class="fa fa-angle-double-up  fa-2x" aria-hidden="true"></i></span>
  </div>
</template>
<script>
export default {
  name: "TopButton",
  methods: {
    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.top-button {
    span{
    display: flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    bottom: 20px;
    right:30px;
    z-index:10;
    font-size:24px;
    background-color:$orange;
    color: white;
    cursor: pointer;
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: all .2s ease;
    &:hover{
     background-color:$black;
     border: none;
     transition: all .2s ease;
    }
    }
}

</style>
