<template>
  <div id="app">
    <Header></Header>
    <router-view />
    <Footer></Footer>
    <TopButton></TopButton>
  </div>
</template>
<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import TopButton from './components/TopButton.vue';
export default {
  components: { Header, Footer,TopButton,},
  name: "App",
};
</script>
<style lang="scss">
@import './_Sass.scss';
html,body{
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  font-family: $font-family;
}
#app {
      // font-family: "Roboto", sans-serif;
      background-color:white;
      color:$black;
      font-size: 14px;
  //================================//
  // modify bootstrap tabs
  .nav-tabs .nav-link {
    color: $black;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color:$orange;
  }
  .card-header {
    background-color:$lightOrange;
  }
  //
}
@media(max-width:426px){
    // modify bootstrap tabs
  .nav-tabs .nav-link {
    font-size: 12px;
    font-weight:bold;
  }
}
</style>
