<template>
  <div class="contact-us">
    <div class="container">
      <div class="row">
        <div class="custom-bg col-12">
          <div class="head">
            <h2>Get In Touch</h2>
            <p> We’re here 24/7 to help you. Get in touch with us from any of these ways:</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="find-us my-4 col-lg-6 col-md-12">
          <div class="how">
            <h4>How TO Find Us</h4>
            <p>
              If you have any questions, just fill in the contact form, and we will answer you shortly. If you are living nearby, come visit RedFoxes at one of our comfortable offices.
            </p>
          </div>
          <div class="headquarters">
            <h4>Headquarters</h4>
              <div>
                  <h6>Address :</h6>
                  <p>Benha, Qalyubia Governorate, Egypt</p>
              </div>
              <div>
                <h6>Phone :</h6>
                <p>002 013 3362227</p>
              </div>
              <div>
                <h6>E-mail :</h6>
                <p>info@redfoxesagency.com</p>
              </div>
              <div class="find-us">
                <h6>Find US On</h6>
                <SocialNetworks></SocialNetworks>
              </div>              
          </div>
        </div>
        <div class="contact-form my-4 col-lg-6 col-md-12">
          <form >
            <h4 class="text-center">Contact Us</h4>
            <div class="form-group">
              <label>Name: </label>
              <input type="text" v-model="form.from_name" class="form-control" />
            </div>
            <div class="form-group">
              <label>Phone: </label>
              <input type="number" v-model="form.from_phone" class="form-control" >
            </div>
            <div class="form-group">
              <label>Email: </label>
              <input type="email" v-model="form.from_email" class="form-control" />
            </div>
            <div class="form group">
              <label>Tell us what you think: </label>
              <textarea class="form-control" v-model="form.message" placeholder="Please write your msg here"></textarea>
            </div>
            <div v-if="sending" class="mt-2 d-flex justify-content-center">
              <ProgressSpinner style="width:50px;height:50px" />
            </div>
            <div v-if="sendingError" class="m-2 d-flex justify-content-center text-danger ">
              <span>Somthing Went wrong ! Please try again</span>
            </div>
            <button class="mt-2" @click.prevent="sendEmail" :disabled="sending">Send Msg</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emailjs from '@emailjs/browser';
import SocialNetworks from '../components/SocialNetworks.vue';
import ProgressSpinner from 'primevue/progressspinner';
export default {
  name: "ContactUS",
    components:{
    SocialNetworks,
    ProgressSpinner
  },
  data(){
    return{
      sending:false,
      sendingError:false,
      form:{
        from_name:'',
        from_phone:'',
        from_email:'',
        message:'',
        to_name:'RedFoxes',
        to_email:'info@redfoxesagency.com',
      },
      //////////////////// SERVICE DATA KEYS ///////////////////////
      
      service_id:'service_7zfifwp',
      template_id:'template_ogpngco',
      user_id:'rAgFHjD8DJGqr3TuY',
      access_token:'CKdVpLv_wNTSaXNpgz8sc'
      
      ////////////////////// SERVICE DATA KEYS ///////////////////////

    }
  },
    methods: {
      sendEmail() {
        this.sending=true;
        this.sendingError=false;
          emailjs.send(this.service_id,this.template_id,this.form,this.user_id)
          .then(res=>{
            console.log(res)
            this.sending=false;
            this.sendingError=false;
            // Reset form field
            this.form={
              from_name:'',
              from_phone:'',
              from_email:'',
              message:'',
              to_name:'RedFoxes',
              to_email:'mahmoudhamed.1195@gmail.com',
            };
          })
          .catch(err=>{
            console.log(err)
            this.sendingError=true;
            this.sending=false;
          })
      },
    },
};
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.contact-us {
  padding-top:90px;
  h4,h2 {
      padding: 10px 0px;
      letter-spacing: 2px;
      color:$orange;
    }
  p{
        line-height: 2;
    }
  .custom-bg {
    background-image: url(../assets/contact.jpg);
    // background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 300px;
    padding: 0;
    .head{
      background-color:rgba($black, 0.3);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p{color: white;
      font-size: 18px;
      text-align: center;}
    }
  }
  .contact-form {
    form {
      padding: 15px;
      box-shadow: $lightblue 0px 5px 10px;
    //display input type number as text type
    /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    /* Firefox */
      input[type=number] {
        -moz-appearance:textfield;
      }
      textarea {
        width: 100%;
        min-height: 100px;
        padding:10px;
      }
      button {
        // margin-top: 20px;
        width: 100%;
         @include mainButton;
      }
    }
  }
}
@media (max-width:426px){
    .contact-us{
        @include smallFontSizes
    }
}
</style>
