<template>
    <div class="social-networks" :class="{'vertical':direction=='vertical'}">
        <div><span class="tw"><a href="#" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></span></div>
        <div><span class="fb"><a href="https://www.facebook.com/REDFOXES.corporation" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></span></div>
        <div><span class="ig"><a href="#" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></span></div>
        <div><span class="li"><a href="https://www.linkedin.com/company/redfoxesofficial" target="_blank" ><i class="fa fa-linkedin" aria-hidden="true"></i></a></span></div>
        <div><span class="yt"><a href="#" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></span></div>
    </div>
</template>
<script>
export default {
    props:['direction']
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.social-networks{
    background-color: $orange;
    border-radius: 10px;
    max-width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-direction: column;
    div{
        padding:5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100px;
        span{
            font-size: 1.5rem;
            i{
                color: white
            };
        }
        &:hover{
            cursor: pointer;
        }
    }
}
.vertical{
    flex-direction: column;
}
</style>