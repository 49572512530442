<template>
    <div class="strategy">
        <div class="container">
            <div class="row">
                <div class="col-12 ">
                    <div class="intro-head">
                        <h3>Strategy</h3>
                        <p>A great brand strategy acts as a guiding light for the brand and all future communication, as well as an objective measure by which to evaluating work and making brand decisions.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="content-list">
                        <h6>Research</h6>
                        <h6>Analysis</h6>
                        <h6>Positioning</h6>
                        <h6>Purpose</h6>
                        <h6>Market Strategy</h6>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="content">
                        <img src="../assets/marketing/Strategy/strategy.svg">
                    </div>
                </div>
            </div>
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>BRAND STRATEGY</h4>
                        <h6>Strategizing Your Path To Growth</h6>
                        <p>What is Brand Strategy? We start by developing a foundation built on strong brand pillars. We then define a brand voice that resonates with your audience and garners a following. We communicate with this new found audience and develop a relationship that converts them to consumers and eventually brand loyalists.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Brand Purpose" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>A powerful brand purpose sets out how a company intends to change the world for the better. Its role is to unite customers and culture alike in the pursuit of that intention. It’s a statement of belief, of hope, of pursuit.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Strategy/BRAND PURPOSE.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Brand Positioning">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>How a brand is perceived in the context of competitive alternatives. Brand positioning statements include a target customer definition, brand essence, brand promise, brand archetype and brand personality, giving the intended brand position/positioning greater depth.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Strategy/BRAND POSITIONING.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Market Strategy">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>A good marketing strategy captures all of a brand's marketing goals and objectives combined into a single comprehensive plan defined to meet business goals.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Strategy/MARKETING STRATEGY.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row work-together">
                <div class="col-12">
                    <div class="talk">
                        <h4>Let’s talk together</h4>
                        <p>We're passionate about innovation, brilliant ideas and the execution that brings it all together in one beautiful experience. If you are too, call or send us an email to get started.</p>
                        <router-link to="/contact-us" tag="button" class="btn">CONTACT US</router-link>
                    </div>        
                </div>    
            </div> 
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.strategy{
    padding-top:100px;
    padding-bottom:30px;
    .intro-head{
        padding: 20px 0px;
        h3{
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
    .content-list{
        h6{
            padding:8px;
            border-radius:1px;
            margin: 10px 0px;
            letter-spacing:2px;
            border-left:3px solid $orange;
        }
    }
    .content{
        height:310px;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            padding: 10px;
        }
    }
    .services{
        .heading{
            padding:20px 0px;
            h4{
                letter-spacing: 2px;
                text-transform: capitalize;
            }
            p{
                line-height: 2;
            }
        }
        .services-panel{
            margin: 15px 0px;
            .desc-img {
                // height:250px;
                width: 100%;
                padding:20px 0px;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
            }
        }
        
    }
    .work-together{
        .talk{
            margin: 10px 0px;
            text-align: center;
            background-color:$lightblue;
            padding:15px 0px;
            text-align: center;
        h4{
            letter-spacing: 2px;
        }
        p{
            padding:20px;
            margin: 0;
            line-height: 2;
        }
        button{
            @include mainButton
        }
    }
    }
}
@media (max-width:426px){
    .strategy{
        @include smallFontSizes
    }
}
</style>