<template>
    <div class="mobile">
        <div class="container">
            <div class="row mobile-company">
                <div class="col-md-6">
                    <div class="heading-txt">
                        <div>
                            <h4>MOBILE APP DEVELOPMENT</h4>
                            <p>We prioritize native app development for all major mobile OS platforms, ensuring the optimal end user experience within a stable, refined and fully defined product</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="heading-img">
                        <img src="../../assets/technology2/Mobile/mobile dev.svg">
                    </div>
                </div>
            </div>
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>SERVICES</h4>
                        <p>We prioritize native app development for all major mobile OS platforms, ensuring the optimal end user experience within a stable, refined and fully defined product</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="CONTENT MANAGEMENT SYSTEMS" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Our custom admin panels are scaled to size but fully rendered and capable of managing all aspects of your mobile app development. We provide custom dashboards to manage and aggregate data and tools to easily manage content, manage and communicate with your mobile-specific users. You lose none of the ability to outsource any data management processes in the mobile development space.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/Mobile/CONTENT MANAGMENT SYSTEMS.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="NATIVE IOS &amp; ANDROID">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>We believe that native app development is the only approach in the mobile space. Native integration gives your end user a superior experience and provides a more stable, refined final product.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/Mobile/NATIVE IOS ANDROID.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="PROTOTYPE">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Our mobile app development process centers around an agile development methodology. You will receive weekly deliverables fully vetted through user feedback and adjusted according to a continuous improvement standard that gives us a flexible approach in the mobile space.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/Mobile/PROTOTYPE.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="INTEGRATIONS &amp; APIS">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>
                                                    We have a deep understanding of complex software infrastructures and develop our platforms and the APIs needed to properly and safely manage, communicate and integrate highly important and sensitive data between various channels and outside sources. Our mobile app development team implements the highest standards, to assure your platforms are efficiently running, effectively processing and securely communicating with any outside networks.
                                                </p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/Mobile/INTEGERATION & APIs.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row tech-process">
                <div class="col-12">
                    <div class="head-txt">
                        <h4>OUR APP DEVELOPMENT PROCESS</h4>
                        <p>Our 8 step mobile app development process ensures the integrity of your vision. From beginning to end, we will walk you through every step.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row steps">
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">1</span> <span>KICK-OFF &amp; DISCOVERY</span></h6>
                                <p>At the beginning of development, we take the time to gather the details that will make your mobile app unique and relevant: details of your company, your target market, competitive challenges, and the vision for your end goal. This crucial stage lays a strong foundation that we build on during the creation of your mobile app(s).</p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">2</span> <span>MOBILE DEVELOPMENT STRATEGY</span></h6>
                                <p>Marketing within the mobile space requires a unique strategy. People behave differently when on their phones, and apps are used for different purposes. We will dissect the core functionalities of your app and provide a strategy to define the platforms and APIs we need to achieve your goals.</p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">3</span> <span>INFORMATION ARCHITECTURE</span></h6>
                                <p>
                                    The information we gather during the strategy phase will now be gathered into an actionable plan. Our development and UX teams work especially closely during this phase to ensure the integrity of technical functions.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">4</span> <span>MOBILE DESIGN PROCESS</span></h6>
                                <p>
                                    Here we test that every strategy we theorize in the steps above is technically achievable. We work out possible process bottlenecks very early to smooth out the execution phase as much as possible.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">5</span> <span>MOBILE CODING &amp; DEVELOPMENT</span></h6>
                                <p>
                                    After you approve your mobile design prototypes, we code the backbone of your app - all completely native to major mobile operating systems.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">6</span> <span>MOBILE QUALITY ASSURANCE</span></h6>
                                <p>After coding and before launch, we test for optimization. Is your app easy to use? Is the code fully valid? Does your app integrate with other native OS features? We answer these questions here and take your app through our QA process.</p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">7</span> <span>LAUNCH</span></h6>
                                <p>We work closely with mobile store reviewers to ensure compliance ahead of launch. Once everything is a go, your app will be on the market!</p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">8</span><span>OPTIMIZATION</span></h6>
                                <p>After launch, we stay with you to ensure that our optimization efforts give you the best launch, not just a viable one. We continue to monitor, analyze and report on the progress of your app to ensure this optimization.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row how-different my-4">
                <div class="col-12">
                    <div class="custom-bg">
                        <h4>How We’re Different</h4>
                        <p>We understand how great an opportunity the mobile environment can be. We know how mobile app users consume content in the space, and we base our design process around simplicity, accessibility and user friendliness.</p>
                        <p>Our team is close knit, and we stay hands on with you throughout the entire mobile design process. Your vision becomes our vision, and we pride ourselves on maintaining the integrity of the creative concept.</p>
                        <p>We outsource nothing. This is the only way to guarantee 100% of our work. Mobile development requires incredibly precise development and quality assurance, and we make sure that you are happy with every step of the process.</p>
                    </div>
                </div>
            </div>
            <div class="row work-together my-4">
                <div class="col-12">
                    <div class="work">
                        <h4>Let’s develop together</h4>
                        <p>We are a tested, trusted &amp; dedicated mobile development company in Egypt. We provide Mobile app development services that help you meet their intended business goals.</p>
                        <router-link to="/contact-us" tag="button" class="btn">CONTACT US</router-link>
                    </div>        
                </div>    
            </div> 
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import '../../_Sass.scss';
.mobile{
    padding-top:100px;
    padding-bottom:20px;
    .mobile-company{
        .heading-txt{
            display: flex;
            align-items: center;
            height: 100%;
            h3{
                letter-spacing: 2px;
                text-transform: capitalize;
                padding: 10px 0px;
            }
            p{
                line-height: 2;
            }
        }
        .heading-img{
            height:300px;
            img{
                height: 100%;
                width: 100%;
                padding: 10px;
            }
        }
    }
    .services{
        .heading{
            padding:20px 0px;
            h4{
                letter-spacing: 2px;
                text-transform: capitalize;
            }
            p{
                line-height: 2;
            }
        }
        .services-panel{
            margin: 15px 0px;
            .desc-img {
                // height:250px;
                width: 100%;
                padding:20px 0px;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
            }
        }
        
    }
    .tech-process{
       .head-txt{
           h4{
               padding: 10px 0px;
           }
       }
       .steps{
           .step{
                background-color:$lightOrange;
                padding: 0px 2px 25px;
                border-radius: 5px;
                margin: 10px 0px;
                height: 230px;
               h6{              
                   display: flex;
                   align-items: center;
                   margin: 0;
                span{
                    margin-right: 5px;
                }
                .custom-size{
                    font-size: 40px;
                    padding-left: 5px;
                    background: -webkit-linear-gradient($orange, #333);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
               }
               p{
                padding: 10px;
                line-height: 2;
                background-color: white;
                font-size: 12px;
                height: 88%; // change with height of txt
               }
           }
       } 
    }
    .how-different{
        .custom-bg{
            background-color:$lightOrange;
            text-align: center;
            height: 100%;
            width: 100%;
            padding: 15px;
            h4{
                margin-bottom:16px;
            }
            p{
                line-height: 2;
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
    .work-together{
        .work{
            text-align: center;
            background-color:$lightblue;
            padding:15px 0px;
            h4{
                letter-spacing: 2px;
            }
            p{
                padding:20px;
                margin: 0;
                line-height: 2;
            }
            button{
                @include mainButton
            }
        }
    }
}
@media (min-width:769px){
.mobile{
    .tech-process{
       .head-txt{
            h4{
            }
            p{
            }
       }
       .steps{
           .step{
                height: 215px;
               h6{              
                span{
                }
                .custom-size{
                }
               }
               p{
                height: 87%; // change with the height of txt
               }
           }
       } 
    }
}
}
@media (max-width:426px){
    .mobile{
        @include smallFontSizes
    }
}
</style>