<template>
    <div class="home2">
        <div class="bg-vid">
            <video id="video-bg" autoplay loop muted>
              <source src="../assets/Particles - 323.mp4" type="video/mp4">
                Your browser does not support HTML5 video.
            </video>
        </div>
        <div class="find-us">
            <SocialNetworks :direction="'vertical'"></SocialNetworks>
        </div>
        <section class="banner-container">
            <div class="container">
                <div class="test">
                    <div class="row top">
                        <div class="col-md-3 offset-md-3 image-wrapper" speed-value="1.2" @click="$router.push({name:'commingSoon'})"></div>
                        <div class="col-md-3 image-wrapper" speed-value="1.6" @click="$router.push({name:'commingSoon'})"></div>
                    </div>
                    <div class="row middle1">
                        <div class="col-md-3 image-wrapper decoration" speed-value="-1.2" @click="$router.push({name:'commingSoon'})"></div>
                        <div class="col-md-3 offset-md-6 image-wrapper hardware" speed-value="-1.7" @click="$router.push({name:'iot',hash: '#hardware'})"></div>
                    </div>
                    <div class="row center">
                        <div class="col-md-6 offset-md-3 image-wrapper" speed-value="1.9" @click="$router.push({name:'home'})"></div>
                    </div>
                    <div class="row middle2">
                        <div class="col-md-3 image-wrapper iot" speed-value="2.5" @click="$router.push({name:'iot'})"></div>
                        <div class="col-md-3 offset-md-6 image-wrapper marketing" speed-value="-1.6" @click="$router.push({name:'marketing'})"></div>
                    </div>
                    <div class="row bottom">
                        <div class="col-md-3 offset-md-3 image-wrapper video" speed-value="1.5" @click="$router.push({name:'video'})"></div>
                        <div class="col-md-3 image-wrapper web" speed-value="-2.5" @click="$router.push({name:'webSolutions'})"></div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <div class="banner-container">
            <div class="container">
                <section class="row">
                    <div class="col-12">
                            <div class="logos-container">
                                <div class="img-object" speed-value="1.2" alt=""></div>
                                <div class="img-object" speed-value="-1.2" alt=""></div>
                                <div class="img-object" speed-value="1.5" alt=""></div>
                                <div class="img-object" speed-value="-1.5" alt=""></div>
                                <div class="img-object" speed-value="1.3" alt=""></div>
                                <div class="img-object" speed-value="-1.3" alt=""></div>
                                <div class="img-object" speed-value="1.7" alt=""></div>
                                <div class="img-object" speed-value="-1.7" alt=""></div>
                                <div class="img-object" speed-value="2" alt=""></div>
                            </div>
                    </div>
                </section>
            </div>
        </div> -->
        <div class="container">
            <div class="row company-name">
                <div class="col-12">
                    <div class="our-products">
                        <div class="our-products-wrapper">
                            <div class="arrow-down">
                                <i class="pi pi-arrow-down"></i>
                            </div>
                            <div class="dash-line"></div>
                            <div class="dash-line"></div>
                            <div class="dash-line"></div>
                            <div class="dash-line"></div>
                            <div class="dash-line"></div>
                            <div>
                                <h3>Our Products</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-12">
                    <Carousel :value="softwareProducts" :numVisible="2" :numScroll="1" :circular="true" :autoplayInterval="3000" :responsiveOptions="responsiveOptions">
                        <template #header>
                            <h3>Software</h3>
                        </template>
                        <template #item="slotProps">
                            <div class="product-item">
                                <div class="product-item-content">
                                    <div>
                                        <Product 
                                            :name="slotProps.data.name"
                                            :btnLabel="slotProps.data.btnLabel" 
                                            :image="slotProps.data.image"
                                            :deadline="slotProps.data.deadline"
                                        >
                                        </Product>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-12">
                    <Carousel :value="hardwareProducts" :numVisible="2" :numScroll="1"  :responsiveOptions="responsiveOptions">
                        <template #header>
                            <h3>Hardware</h3>
                        </template>
                        <template #item="slotProps">
                            <div class="product-item">
                                <div class="product-item-content">
                                    <div>
                                        <Product 
                                            :name="slotProps.data.name"
                                            :btnLabel="slotProps.data.btnLabel" 
                                            :image="slotProps.data.image"
                                            :deadline="slotProps.data.deadline"
                                            :route="slotProps.data.route"
                                        >
                                        </Product>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-12">
                    <Carousel :value="videoProduction" :numVisible="2" :numScroll="1" :circular="true" :autoplayInterval="3000" :responsiveOptions="responsiveOptions">
                        <template #header>
                            <h3>Video Production</h3>
                        </template>
                        <template #item="slotProps">
                            <div class="product-item">
                                <div class="product-item-content">
                                    <div>
                                        <iframe :src="slotProps.data.source" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Carousel from 'primevue/carousel';
import SocialNetworks from '../components/SocialNetworks.vue';
import Product from '../components/Product.vue';
export default {
    components:{
        Carousel,
        SocialNetworks,
        Product,
    },
    data(){
        return {
            videoProduction:[
                {
                    source:'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FBayoumiFouadofficialPage%2Fvideos%2F786095895613455%2F&show_text=false&width=560&t=0',
                },
                {
                    source:'https://www.youtube.com/embed/6apMaGEI-Lw',
                },
                {
                    source:'https://www.youtube.com/embed/PkcztCf-Yx4',
                },
            ],
            softwareProducts:[
                {
                    image:require('../assets/technology2/products/soft/healthsvg.svg'),
                    name:'We Helath It Webapp',
                    deadline:"2023-01-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/soft/ART.svg'),
                    name:'The Art Community Website',
                    deadline:"2025-04-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/soft/IOT CLOUD COMPUTING.svg'),
                    name:'IOT Cloud Computing',
                    deadline:"2023-10-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/soft/PLAY.svg'),
                    name:'Gaming Plateform',
                    deadline:"2024-10-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/soft/REAL STATE.svg'),
                    name:'Properties For Sale Website',
                    deadline:"2024-04-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/soft/SPORT APP.svg'),
                    name:'Sport App For Football',
                    deadline:"2024-04-1 00:00:00",
                    btnLabel:'More Info'
                },
            ],
            hardwareProducts:[
                {
                    image:require('../assets/technology2/products/hard/DIABETES.png'),
                    name:'Diabetes Smart Device',
                    deadline:"2023-10-1 00:00:00",
                    btnLabel:'More Info',
                    route:{name:'diabetes'}
                    
                },
                {
                    image:require('../assets/technology2/products/hard/IOT.svg'),
                    name:'Iot Hardwares',
                    deadline:"2023-10-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/hard/ProCuity_diagonal_view_trim.webp'),
                    name:'Smart Medical Bed',
                    deadline:"2023-10-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/hard/smart watch.svg'),
                    name:'Smart Watch Medical',
                    deadline:"2023-04-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/hard/STERILIZATION DEVICE.png'),
                    name:'Blue Shift',
                    deadline:"2023-10-1 00:00:00",
                    btnLabel:'More Info',
                    route:{name:'blueShift'}
                },
            ],
            responsiveOptions: [
				{
					breakpoint: '1023px',
					numVisible: 1,
					numScroll: 1
				},
			]
        }
    },
    mounted(){
        document.addEventListener("mousemove",parallax);
        function parallax(e){
            document.querySelectorAll(".image-wrapper").forEach(function(move){
                let movingValue=move.getAttribute("speed-value");
                let x = (e.clientX*movingValue)/200
                let y = (e.clientY*movingValue)/200
                move.style.transform="translateX("+ x +"px) translateY("+ y +"px)";
            });
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.home2{
    width: 100%;
    padding-top:90px;
    background-color: black;
    .bg-vid{
        // width: 100%;
        // position: fixed;
        // top: 0;
        // z-index: 1;
        #video-bg{
            position: fixed;
            right: 0;
            bottom: 0;
            min-width: 100%; 
            min-height: 100%;
        }
    }
    button{
        @include mainButton
    }
    p{
        line-height: 2;
        margin-bottom: 0;
    }
    h2,h3{
        text-transform: uppercase;
    }
    .find-us{
        position: absolute;
        top:15%;
        left:0%;
        z-index: 5;
        // transform: rotate(-90deg);
        // transform: translateX(-10px);
        // transform: rotate(-90deg);
    }
    .banner-container {
        .test{
            z-index: 2;
            position: relative;
            .row{
                .image-wrapper{
                    height:150px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size:contain;    
                    transition:background-image 0.7s ease-in-out;
                    &:hover{
                        transition:background-image 0.7s ease-in-out;
                        cursor: pointer;
                    }
                }
            }
                .top{
                    div:nth-of-type(1){
                            background-image:url("../assets/LOGO/DATA ANALYTICS GRAY.png");
                        &:hover{
                            background-image:url("../assets/LOGO/DATA ANALYTICS.png");
                        }
                    }
                    div:nth-of-type(2){
                        background-image:url("../assets/LOGO/DATA CENETERS GRAY.png");
                        &:hover{
                            background-image:url("../assets/LOGO/DATA CENETERS.png");
                        }
                    }
                }
                .middle1{
                    div:nth-of-type(1){
                        background-image:url("../assets/LOGO/DECORATION GRAY.png");
                        &:hover{
                            background-image:url("../assets/LOGO/DECORATION.png");
                        }
                    }
                    div:nth-of-type(2){
                        background-image:url("../assets/LOGO/HARDWARE DEVELOPMENT GRAY.png");
                        &:hover{
                            background-image:url("../assets/LOGO/HARDWARE DEVELOPMENT.png");
                        }
                    }
                }
                .center{
                    div:nth-of-type(1){
                        background-image:url("../assets/logo.png");
                    }
                }
                .middle2{
                    div:nth-of-type(1){
                        background-image:url("../assets/LOGO/IOT & SMART HOMES GRAY.png");
                        &:hover{
                            background-image:url("../assets/LOGO/IOT & SMART HOMES.png");
                        }
                    }
                    div:nth-of-type(2){
                        background-image:url("../assets/LOGO/MARKETING AGENCY GRAY.png");
                        &:hover{
                            background-image:url("../assets/LOGO/MARKETING AGENCY.png");
                        }
                    }
                }
                .bottom{
                    div:nth-of-type(1){
                        background-image:url("../assets/LOGO/STUDIOS GRAY.png");
                        &:hover{
                            background-image:url("../assets/LOGO/STUDIOS.png");
                        }
                    }
                    div:nth-of-type(2){
                        background-image:url("../assets/LOGO/WEB & SOFTWARE GRAY.png");
                        &:hover{
                            background-image:url("../assets/LOGO/WEB & SOFTWARE.png");
                        }
                    }
                }
        }
    }
    .banner-container{
        .logos-container{
            z-index: 2;
            position: relative;
            height:1000px;
            top: -8%;
            div{
                width:180px;
                height:180px;
                position:absolute;
                background-repeat: no-repeat;
                background-size: cover;    
                transition:background-image 0.7s ease-in-out;
                &:hover{
                    transition:background-image 0.7s ease-in-out;
                    cursor: pointer;
                }
            }
            div:nth-of-type(1){
                top:30%;
                left:5%;
                    background-image:url("../assets/LOGO/DATA ANALYTICS GRAY.png");
                &:hover{
                    background-image:url("../assets/LOGO/DATA ANALYTICS.png");
                }
            }
            div:nth-of-type(2){
                top:30%;
                right:5%;
                background-image:url("../assets/LOGO/DATA CENETERS GRAY.png");
                &:hover{
                    background-image:url("../assets/LOGO/DATA CENETERS.png");
                }
            }
            div:nth-of-type(3){
                top:15%;
                left:25%;
                background-image:url("../assets/LOGO/DECORATION GRAY.png");
                &:hover{
                    background-image:url("../assets/LOGO/DECORATION.png");
                }
            }
            div:nth-of-type(4){
                bottom:15%;
                left:25%;
                background-image:url("../assets/LOGO/HARDWARE DEVELOPMENT GRAY.png");
                &:hover{
                    background-image:url("../assets/LOGO/HARDWARE DEVELOPMENT.png");
                }
            }
            div:nth-of-type(5){
                top:15%;
                right:25%;
                background-image:url("../assets/LOGO/IOT & SMART HOMES GRAY.png");
                &:hover{
                    background-image:url("../assets/LOGO/IOT & SMART HOMES.png");
                }
            }
            div:nth-of-type(6){
                bottom:15%;
                right:25%;
                background-image:url("../assets/LOGO/MARKETING AGENCY GRAY.png");
                &:hover{
                    background-image:url("../assets/LOGO/MARKETING AGENCY.png");
                }
            }
            div:nth-of-type(7){
                bottom:30%;
                left:5%;
                background-image:url("../assets/LOGO/STUDIOS GRAY.png");
                &:hover{
                    background-image:url("../assets/LOGO/STUDIOS.png");
                }
            }
            div:nth-of-type(8){
                bottom:30%;
                right:5%;
                background-image:url("../assets/LOGO/WEB & SOFTWARE GRAY.png");
                &:hover{
                    background-image:url("../assets/LOGO/WEB & SOFTWARE.png");
                }
            }
            div:nth-of-type(9){
                width: 200px;
                height: 200px;
                top:40%;
                left:40%;
                background-image:url("../assets/logo.png");
                // transform: translate(-50%,-50%);
            }
        }
    }
    .company-name{
        position: relative;
        z-index: 2;
        color: white;
        span{
            color: $red;
        }
    }
    .p-carousel {
        position: relative;
        z-index: 2;
        color: white;
        font-family: $font-family;
    }
    /deep/.p-carousel-indicator.p-highlight .p-link {
        background-color: $orange;
    }
    /deep/.product-item,.product-item-content,.product-item-content>div {
        height: 100%;
        padding:0px 1px;
    }
    iframe{
        width: 100%;
        height:250px;
    }
    .our-products{
        .our-products-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .arrow-down{
            width:50px;
            height: 100px;
            color: $red;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid $red;
            border-radius: 34px;
            // margin-left: 50px;
            margin-bottom: 10px;
            animation: animated-arrow 2s infinite;
            animation-direction: alternate;
        }
        .dash-line{
            width: 2px;
            height: 10px;
            background: $red;
            margin: 3px 0px;
            // margin-left: 75px;
        }
        h3{
                margin-top: 10px;
                @include underLine
            }
    }
    @keyframes animated-arrow {
        0% {
            transform: translate(0, -5px);    
        }
        100% {
            transform: translate(0, 5px);
        }
    }
}
@media (max-width:426px){
    .home2{
        @include smallFontSizes;
    }
}
</style>