<template>
    <div class="products">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-center my-5">SOFTWARE</h3>
                </div>
                <div class="col-lg-6 my-4 item" v-for="product in softwareProducts" :key="product">
                    <Product  :image="product.image" :name="product.name" :deadline="product.deadline" :btnLabel="product.btnLabel"></Product>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="text-center my-5">HARDWARE</h3>
                </div>
                <div class="col-lg-6 my-4 item" v-for="product in hardwareProducts" :key="product">
                    <Product  :image="product.image" :name="product.name" :deadline="product.deadline" :btnLabel="product.btnLabel" :route="product.route"></Product>
                </div>
            </div>
            
            <div class="row">
                <div class="col-12">
                    <h3 class="text-center my-5">VIDEO PRODUCTION</h3>
                </div>
                <div class="col-lg-6 my-4 item" v-for="product in videoProduction" :key="product">
                    <iframe :src="product.source" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Product from '../components/Product.vue';
export default {
    components:{Product},
    data(){
        return{
            softwareProducts:[
                {
                    image:require('../assets/technology2/products/soft/healthsvg.svg'),
                    name:'We Helath It Webapp',
                    deadline:"2023-01-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/soft/ART.svg'),
                    name:'The Art Community Website',
                    deadline:"2025-04-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/soft/IOT CLOUD COMPUTING.svg'),
                    name:'IOT Cloud Computing',
                    deadline:"2023-10-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/soft/PLAY.svg'),
                    name:'Gaming Plateform',
                    deadline:"2024-10-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/soft/REAL STATE.svg'),
                    name:'Properties For Sale Website',
                    deadline:"2024-04-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/soft/SPORT APP.svg'),
                    name:'Sport App For Football',
                    deadline:"2024-04-1 00:00:00",
                    btnLabel:'More Info'
                },
            ],
            hardwareProducts:[
                {
                    image:require('../assets/technology2/products/hard/DIABETES.png'),
                    name:'Diabetes Smart Device',
                    deadline:"2023-10-1 00:00:00",
                    btnLabel:'More Info',
                    route:{name:'diabetes'}
                    
                },
                {
                    image:require('../assets/technology2/products/hard/IOT.svg'),
                    name:'Iot Hardwares',
                    deadline:"2023-10-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/hard/ProCuity_diagonal_view_trim.webp'),
                    name:'Smart Medical Bed',
                    deadline:"2023-10-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/hard/smart watch.svg'),
                    name:'Smart Watch Medical',
                    deadline:"2023-04-1 00:00:00",
                    btnLabel:'More Info'
                },
                {
                    image:require('../assets/technology2/products/hard/STERILIZATION DEVICE.png'),
                    name:'Blue Shift',
                    deadline:"2023-10-1 00:00:00",
                    btnLabel:'More Info',
                    route:{name:'blueShift'}
                },
            ],
            videoProduction:[
                {
                    source:'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FBayoumiFouadofficialPage%2Fvideos%2F786095895613455%2F&show_text=false&width=560&t=0',
                },
                {
                    source:'https://www.youtube.com/embed/6apMaGEI-Lw',
                },
                {
                    source:'https://www.youtube.com/embed/PkcztCf-Yx4',
                },
            ],
        }
    },
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.products{
    padding-top:90px;
    padding-bottom:30px;
    margin: 0px auto;
    height: 100%;
    h3{
        @include underLine
    }
    .head{
        width: 100%;
        p{
            line-height: 2;
            text-align: center;
        }
    }
    .item{
        iframe{
            width: 100%;
            height: 300px;
        }
    }
}
@media (max-width:426px){
    .products{
        @include smallFontSizes
    }
}
</style>