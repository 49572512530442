<template>
    <div class="seo">
        <div class="container">
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h3>SEARCH ENGINE OPTIMIZATION</h3>
                        <h6>Optimization for all your business needs</h6>
                        <p>We help websites start working hard for you. Having an online presence was the first step to expanding your services online, now you need to make sure your investment is optimized to get the search results you deserve. Our Search Engine Optimization services are catered to deliver results, no matter the type of business/services your provide.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="NATIONAL SEO" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>We’ll help you rank competitively at a national level for the best, most relevant search terms for your business.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/seo/national-seo.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="MULTI-LOCATION SEO">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>If your business has multiple locations, we can offer SEO services that help you connect with your audience in each unique area.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/seo//multi location seo.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="BRANDING SEO">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>SEO and branding are more related than you might think. Because the way search algorithms rank quality impacts your SEO, your branding and authority should tie in to your SEO strategy a very big way.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/seo/branding seo.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                  <b-tab title="ECOMMERCE SEO">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Get a leg up on the competition in your eCommerce category with smart targeting and relevant SEO for your niche.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/seo/service-ecommerce seo.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                  <b-tab title="WORDPRESS SEO">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>If your site runs on WordPress and you’re looking for assistance with SEO, look no further. We know all the ins and outs that dramatically improve your website’s performance.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/seo/wordpress-seo.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row why-redfoxes">
                <div class="col-12 content">
                    <h4>Why Choose Redfoxes?</h4>
                    <p>Our expertise in the SEO field comes from the constant pursuit of knowledge. As web developers and programmers, we naturally gravitate toward the “how it works” of technology so we can better understand the potential application it can have for our clients.</p>
                    <p>With each and every website project or mobile application we develop, the next conversation is always about marketing—which is why we decided to offer a strategic SEO service to help meet the needs of our clients at any stage in the SEO lifecycle. We pride ourselves on being transparent and on having the ability to clearly communicate and demonstrate the SEO strategy we plan to deploy.</p>
                </div>
            </div>
            <div class="row opt-process">
                <div class="col-12 heading">
                    <h4>OUR SEARCH ENGINE OPTIMIZATION PROCESS</h4>
                    <h6>The right strategy for your business</h6>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="1- IN-DEPTH AUDIT" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>We begin each new project with an in-depth review of current website traffic sources, domain authority, page authority, keyword ranking, pages indexed in Google—and many other attributes.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/seo/audit-services.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="2- Why &amp; How">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Next, we look at the reasoning behind why and how a client wants to be found for specific keyword phrases, and have a comprehensive conversation about the best way to approach a solution.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/seo/why & how.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="3- STRATEGY">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>We then formulate a strategy that forecasts future events, milestones, and objectives and establishes clear metrics that will be our baseline upon which we gauge success.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/seo/strategy.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="4- OPTIMIZATION &amp; AMPLIFICATION">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>We continue to optimize your keywords, establish new ones and begin our link building efforts to further solidify your search results.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/seo/optimization.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row how-different">
                <div class="col-12 custom-bg">
                    <h4>How We’re Different</h4>
                    <p>There is no “one size fits all” SEO package. Everything we do is customized based on the client’s goals, current positioning, and resources. We also never say we can do something that we can’t. If we feel that the scope of the project is unlikely to succeed, we will not pursue it.</p>
                    <p>At Redfoxes, we also have a specialty in executing “Local SEO” for businesses looking to attract nearby customers. This can work in tandem with other SEO efforts or as a standalone strategy for national and regional brands or retailers with multiple physical locations..</p>
                    <p>Our primary goal for any SEO project is to increase overall exposure for clients and to drive them the most qualified traffic possible.</p>
                </div>
            </div>
            <div class="row work-together">
                <div class="col-12 talk">
                        <h6>Struggling to push your website search ranking?</h6>
                        <h4>Let’s talk together</h4>
                        <p>You need to hire an experienced SEO company that boost your rankings. Whether it’s a new venture or long running corporate, we are there to guide you through all the challenges to draw more prospective customers for business generation.</p>
                        <router-link to="/contact-us" tag="button" class="btn">CONTACT US</router-link>   
                </div>    
            </div> 
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.seo{
    padding-top:100px;
    padding-bottom:20px;
    background-color: white;
    color: black;
    overflow:hidden;
    .intro-head{
        padding: 20px 0px;
        h3{
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
    .content-list{
        h6{
            padding:8px;
            border-radius:1px;
            margin: 10px 0px;
            letter-spacing:2px;
            border-left:3px solid $orange;
        }
    }
    .content{
        height:310px;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            padding: 10px;
        }
    }
    .services{
        .heading{
            padding:20px 0px;
            h3{
                letter-spacing: 2px;
                text-transform: capitalize;
            }
            p{
                line-height: 2;
            }
        }
        .services-panel{
            margin: 15px 0px;
            .desc-img {
                height:250px;
                width: 100%;
                padding:20px 0px;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
                button{
                    @include mainButton
                }
            }
        }
        
    }
    .why-redfoxes{
        margin: 20px 0px;
        .content{
            text-align: center;
            height: 100%;
            background-color:$lightblue;
            padding: 15px 0px;
            text-align: center;
        h4{
            letter-spacing: 2px;
            padding: 10px 0px;
        }
        p{
            padding:0px 20px;
            margin: 0;
            line-height: 2;
        }
        }
    }
    .opt-process{
            .heading{
                margin: 20px 0px;
            }
            .services-panel{
            margin: 15px 0px;
            .desc-img {
                height:250px;
                padding:20px 0px;
                img{
                    width: 100%;
                    height: 100%;
                    // object-fit: cover;

                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
                button{
                    @include mainButton
                }
            }
        }
    }
    .how-different{
        margin: 20px 0;
        .custom-bg{
            background-color:$lightOrange;
            text-align: center;
            height: 100%;
            width: 100%;
            padding: 15px ;
            h4{
                margin-bottom:16px;
            }
            p{
                padding:0px 10px;
                line-height: 2;
            }
        }
    }
    .work-together{
        margin: 20px 0px;
        .talk{
            text-align: center;
            height: 100%;
            width: 100%;
            background-color:$lightblue;
            padding: 15px;
            text-align: center;
        h4{
            letter-spacing: 2px;
            padding: 10px 0px;
        }
        p{
            padding:15px;
            margin: 0;
            line-height: 2;
        }
        button{
            @include mainButton
        }
    }
    }
}
</style>