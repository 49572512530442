<template>
  <div>
    <flip-countdown :deadline="deadline"></flip-countdown>
  </div>
</template>
<script>
  import FlipCountdown from 'vue2-flip-countdown';
  export default {
    components: { FlipCountdown },
    props:{
      deadline:{
        type:String,
      }
    },

  }
</script>
<style lang="scss" scoped>
/deep/ .container.flip-clock{
    padding: 0;
    width: 100%;
    display: flex;
}
/deep/ .flip-clock__piece.flip{
    padding: 0;
    margin-left: 0;
}
/deep/ .flip-card{
    font-size: 1.5rem !important;
}
/deep/ .flip-clock__slot{
    font-size: 0.75rem !important;
}
@media (max-width:1200px){
  /deep/ .flip-card{
      font-size: 1.2rem !important;
  }
  /deep/ .flip-clock__slot{
      font-size: 0.5rem !important;
  }
}
</style>