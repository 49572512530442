<template>
    <div class="career">
        <div class="container">
            <div class="row job-intro my-4">
                <div class="col-md-6">
                    <div class="head-txt">
                        <div>
                            <h4>STAY INFORMED</h4>
                            <p>Join our Talent Community to receive via email information about RedFoxes, upcoming events, and job alerts.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="head-img">
                        <!-- <div class="img-wrapper">
                            <img src="../assets/career/head.svg">
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row info my-4">
                <div class="col-md-6">
                    <div class="head-img">
                        <div class="img-wrapper">
                            <img src="../assets/career/career.svg">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form">
                        <h4 class="text-center">Join US</h4>
                        <form>
                            <div class="form-group">
                                    <label>Name:</label>
                                    <input type="text" v-model="form.name" class="form-control">
                            </div>
                            <div class="form-group">
                                    <label>Email:</label>
                                    <input type="email" v-model="form.email" class="form-control">
                            </div>
                            <div class="form-group">
                                    <label>Phone:</label>
                                    <input type="number" v-model="form.phone" class="form-control">
                            </div>
                            <div class="form-group">
                                    <label>Tell us what you think: </label>
                                    <textarea class="form-control" v-model="form.message" placeholder="Please write your msg here"></textarea>
                            </div>
                            <div v-if="sendingError" class="m-2 d-flex justify-content-center text-danger ">
                                <span>Somthing Went wrong ! Please try again</span>
                            </div>
                            <div class="text-center" v-if="uploadValue>0 && uploadValue<100">
                                <ProgressSpinner style="width:50px;height:50px" />
                                <h6>Please Wait while Uploading ...</h6>
                                <h6>{{uploadValue.toFixed()}} %</h6>
                            </div>
                            <div class="form-group">
                                    <label for="upload-cv" class="upload-cv">Upload your cv</label>
                                    <input :disabled="uploadValue>0 && uploadValue<100" type="file" @change="getRawFile" accept = "application/pdf" id="upload-cv" class="form-control">
                                    <button class="submit-form" @click.prevent="sendMessage" :disabled="uploadValue>0 && uploadValue<100">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { db,storage } from '../firebase/config'
import ProgressSpinner from 'primevue/progressspinner';
export default {
        components:{
            ProgressSpinner,
        },
    data(){
        return{
            sending:false,
            sendingError:false,
            form:{
                name:'',
                email:'',
                phone:'',
                message:'',
                cv:''
            },
            rawFile:null,
            uploadValue:0
        }
    },
    mounted(){
        // this.getDocuments()
    },
    methods:{
        getRawFile(event){
            this.rawFile = event.target.files[0];
        },
        sendMessage(){
            this.onUpload()
        },
        onUpload(){
            this.sending=true;
            this.sendingError=false;
            const storageRef = storage.ref(`${this.rawFile.name}`).put(this.rawFile);
            storageRef.on(`state_changed`,
                snapshot=>{this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100},
                error=>{console.log(error.message);this.sendingError=true;this.sending=false;},
                ()=>{this.uploadValue=100;
                     storageRef.snapshot.ref.getDownloadURL().then((url)=>{
                        this.form.cv =url;
                        db.collection('userMessages').add(this.form)
                        .then(()=>{
                            this.sending=false;
                            this.sendingError=false;
                            this.form={
                                name:'',
                                email:'',
                                phone:'',
                                message:'',
                                cv:''
                            },
                            this.rawFile=null,
                            this.uploadValue=0
                        })
                        .catch(()=>{
                            this.sending=false;
                            this.sendingError=true;
                        })
                    });
                }      
            );
        },
        // Get all documents in a collection
        getDocuments(){
            db.collection("userMessages").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                });
            });
        },
    },
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.career{
    padding-top: 100px;
    padding-bottom: 30px;
    .job-intro{
        .head-txt{
            display: flex;
            align-items: center;
            height: 100%;
            h4{
            padding: 10px 0px;
            letter-spacing: 2px;
            color:$orange;
            }
            p{
                    line-height: 2;
                    margin-bottom: 0;
            }
        }
        .head-img{
            display: flex;
            justify-content: center;
            align-items: center;
            .img-wrapper{
                // height:auto;
                    height: 100%;
                img{
                    height: 100%;
                    width: 100%;
                    padding:15px;
                }
            }
        }
    }
    .info{
        .head-img{
            display: flex;
            align-items:center;
            justify-content: center;
            height: 100%;
            .img-wrapper{
                height: 100%;
                img{
                    height: 100%;
                    width: 100%;
                    padding:15px;
                }
            }
        }
        .form{
            padding: 15px;
            box-shadow: $lightblue 0px 5px 10px;
        //display input type number as text type
        /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }
        /* Firefox */
            input[type=number] {
            -moz-appearance: textfield;
            }
            h4{
                color:$orange;
            }
            textarea {
                width: 100%;
                min-height:100px;
                padding:10px;
            }
            #upload-cv{
                display: none;
            }
            .upload-cv{
                background-color:rgba($black,0.85);
                padding: 10px;
                border-radius: 3px;
                color: white;
                text-align: center;
                width:40%;
                &:hover{
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
            .submit-form{
                margin-left:10%;
                width: 50%;
                @include mainButton
            }
        }
    }
}
@media (max-width:426px){
    .career{
        @include smallFontSizes
    }
}
</style>