<template>
    <div class="branding">
        <div class="container">
            <div class="row">
                <div class="col-12 ">
                    <div class="intro-head">
                        <h3>Branding</h3>
                        <p>Branding is one of the most crucial aspects of any business, large or small. An effective brand can give you a significant edge  in today’s highly competitive market.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="branding-list">
                        <h6>Identity</h6>
                        <h6>Voice</h6>
                        <h6>Psitioning</h6>
                        <h6>Audience &amp; Competitor Analysis</h6>
                        <h6>Consumer Journeys</h6>
                        <h6>Brand Guidelines</h6>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="content">
                        <img src="../assets/marketing/Branding/branding.svg">
                    </div>
                </div>
            </div>
            <div class="row working-with">
                <div class="col-md-6">
                    <div class="img">
                        <img src="../assets/marketing/Branding/BRANDING.png">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="txt">
                        <p>
                            We love working with young startups and fellow entrepreneurs to help create impressive voice and vision. Our proficient team endeavors to solve large complex problems that help established brands, break new verticals.
                            At REDFOXES, we value our clients’ creative identities, and offer solutions that sync with their strategic aspirations.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>Branding Services</h4>
                        <p>A great brand strategy acts as a guiding light for the brand and all future communication, as well as an objective measure by which to evaluating work and making brand decisions. We walk you through the process of discovery, research and positioning to achieve the expected business results.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Brand Visual Identity" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>The visual aspects that form an overall brand can leave a strong impression, and we all know first impressions are everything. We work with our clients to articulate their visual identity, logo design, brand imagery and custom iconography.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Branding/BRAND VISUAL IDENTITY.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Brand Voice">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>A brand’s verbal language is just as important as its visual language. We determine the right tone to interact with your audience to determine successful campaign launches and a genuine relationship between your brand and the community.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Branding/BRAND VOICE.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Brand Strategy">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>What is Brand Strategy? We start by developing a foundation built on strong brand pillars. We then define a brand voice that resonates with your audience and garners a following. We communicate with this newfound audience and develop a relationship that converts them to consumers and eventually brand loyalists.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Branding/BRAND STRATEGY.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Brand Guidelines">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Photography and graphical imagery is also a major tone-setter with brands. We explore and establish an overall Art Direction to work across all mediums and platforms. Digital, print and television all work seamlessly under a solid Art Direction.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Branding/BRAND GUIDELINE.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="how">
                        <h4>How We’re Different</h4>
                        <p>There are already so many branding firms and agencies in the marketplace, and it’s only becoming more and more saturated every day. At redfoxes , we try to make this decision as easy as possible for the client. Our work speaks for itself and our passion shows through the high level of strategy and thought in every brand we launch.</p>
                        <p><mark>Bonus:</mark> We don’t outsource any aspects of our work, which means we can 100% guarantee high standards for each and every project.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="talk">
                        <h4>Let’s talk Branding</h4>
                        <p>Discover a new side to your company with a dedicated & innovative branding agency in Middle East. Looking to rebrand or start from the scratch? Give us a call, our experienced branding team is here to guide you through the process.</p>
                        <router-link to="/contact-us" tag="button" class="btn">CONTACT US</router-link>
                    </div>    
                </div>
            </div>  
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.branding{
    padding-top:100px;
    padding-bottom:20px;
    .intro-head{
        padding: 20px 0px;
        h3{
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
    .branding-list{
        h6{
            padding:8px;
            margin: 10px 0px;
            letter-spacing:2px;
            border-left:3px solid $orange;
        }
    }
    .content{
        height:310px;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            padding: 10px;
        }
    }
    .working-with{
        padding: 20px 0px;
        margin: 20px 0px;
        display: flex;
        align-items: center;
        background-color:$lightblue;
        .img{
            height: 250px;
            img{
                width: 100%;
                height:100%;
                padding: 10px 0px;
            }
        }
        p{
            line-height: 2;
            margin: 0;
            text-align: center;
        }
    }
    .services{
        .heading{
            padding:20px 0px;
            h4{
                letter-spacing: 2px;
                text-transform: capitalize;
            }
            p{
                line-height: 2;
                margin: 0;
            }
        }
        .services-panel{
            margin:20px 0px;
            .desc-img{
                // height:250px;
                padding:20px 0px;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
            }
        }
        
    }
    .how,.talk{
        padding: 20px 0px;
        text-align: center;
        background-color:$lightOrange;
        h4{
            letter-spacing: 2px;
            padding: 10px 0px;
        }
        p{
            padding:20px;
            margin: 0;
            line-height: 2;
        }
        button{
            margin: 20px 0px;
        }
    }
    .talk{
        background-color:$lightblue;
        margin: 20px 0px;
        button{
            @include mainButton
        }
    }
}
@media (max-width:426px){
    .branding{
        @include smallFontSizes
    }
}
</style>