<template>
    <div class="web">
        <div class="container">
            <div class="row web-company">
                <div class="col-md-6">
                    <div class="heading-txt">
                        <div>
                            <h3>WEB DEVELOPMENT COMPANY</h3>
                            <h6>Web developers you will love working with</h6>
                            <p>Are you looking for a friendly, knowledgable web development company? Then look no further, our team of developers strive hard to offer an unparalleled user experience to all of our esteemed clientele in and around middle east.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="heading-img">
                        <video autoplay loop muted>
                            <source src="../../assets/technology2/Web/web development.mp4" type="video/mp4">
                            Your browser does not support HTML5 video.
                        </video>
                    </div>
                </div>
            </div>
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>WEB DEVELOPMENT SOLUTIONS</h4>
                        <h6>Our platform agnostic approach</h6>
                        <p>What does it mean when we say a platform-agnostic approach? We want to deliver a platform that meets every business need. We don't want to just push you into any technology. We evaluate the scale, security requirements, backend integrations, APIs needed and give you recommendations, with pluses and minuses for each platform, so you understand each platform's strengths and weaknesses.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Wordpress Development" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Need a website that can help you leverage the power of SEO to attract prospects? Then we're your dream team. Our web developers are well experienced in PHP, HTML 5, CSS and more. We can quickly build you a responsive and keyword-optimized website that can help you touch base with your prospects and existing customers.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/Web/CMS WORDPRESS.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Drupal Development">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Our team of web developers can build you a customized Drupal site too. Our web development services are designed to help you handle your content management operations with greater flexibility and efficiency. With its state-of-the-art CMS tools and high-security features, your new Drupal-hosted website will give you a leg up against competitors.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/Web/CMS DRUPAL.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Wix &amp; Squarespace">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>At Redfoxes, we work with companies with various levels of technical web maintenance knowledge. If your team prefers simple-to-use websites that can easily be operated and managed, then our Wix web development and Squarespace website development services will be of enormous value. From developing a brand new Wix or Squarespace website to scheduled upgrades, we can do them all for you on a budget.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/Web/WIX.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Adobe Experience Manager Development">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Streamline your operations and manage all your e-commerce stores and websites under one roof, using the Adobe experience manager CMS. Whether its tools, processes or people, the Adobe experience manager allows you to monitor and control all elements of your website.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../../assets/technology2/Web/ADOBE EXPERIENCE MANAGER DEVELOPMENT.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="row tech-process">
                <div class="col-12">
                    <div class="head-txt">
                        <h4>WEB DEVELOPMENT PROCESS</h4>
                        <h6>Tailored web development process to fit your needs</h6>
                        <p>Our web development process follows a hybrid development approach, where we build the MVP using a Waterfall methodology, and then add new features and improvements in Agile sprints, to ensure we are working efficiently and hitting our delivery dates.</p>
                        <p>Every build is assigned a dedicated technical Project Manager, who will support your equally dedicated Account Director in efficiently managing work quality, time, and budget.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row steps">
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">1</span> <span>Discovery</span></h6>
                                <p>We begin by assessing your current environment and technology stack, speaking to your key stakeholders and tech leads. Our goal is to understand your business needs and learn how these inform your technology requirements. If we’re also designing your website, technical discovery is part of an integrated discovery and strategy process.</p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">2</span> <span>Analysis</span></h6>
                                <p>Before we define any approach, we need to always understand and define the ultimate development stack that your project will need to achieve its overall goal. Our technology team provides a full analysis of site content, traffic, security requirements, and delivers a full description of proper platforms and APIs needed to achieve these goals within budget allocations.</p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">3</span> <span>User Experience</span></h6>
                                <p>
                                    We’re an end-to-end website development company, so we are more than capable of handling your digital strategy,      information architecture, website design, and content creation.
                                    We begin planning early for the features we know the site will need so that we can scope development appropriately. And we architect and design the website with an understanding of the unique capabilities of the platform the site will be built on.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">4</span> <span>Front-End &amp; Back-End Development</span></h6>
                                <p>
                                    Our development teams are fluent in the whole range of modern web development languages, including PHP, .Net, Java, HTML5, CSS, python and JavaScript. With our range of partnerships, we have the expertise – including many certifications – for a wide variety of CMS options.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">5</span> <span>Quality Assurance</span></h6>
                                <p>Before we launch any project, we will assess how optimized it is, how easy to use it is for clients, whether its code is valid. We also ensure that it gives you value for your money and passes our high quality assurance standards for your all your development needs..</p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">6</span> <span>Launch &amp; Optimization</span></h6>
                                <p>If everything passes our rigorous quality assurance development process, we then proceed to launch. We train your staff on using it, begin marketing plan execution and start analyzing its results to see its progress. And unlike other companies, we do not just leave you after launch. We proceed to monitor, analyze and report on your project's progress in order to ensure its performing at peak level.</p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12">
                            <div class="step">
                                <h6><span class="custom-size">7</span><span> Optimization</span></h6>
                                <p>After launch we stick around, continue to monitor your site for browser issues, speed tests, security issues and also template optimizations. We want to collect data, test and optimize your new templates to ensure they are working and converting. Ensuring your site is in tip-top shape and working hard for your business.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row work-together">
                <div class="col-12">
                    <div class="work">
                        <h4>Let’s develop together</h4>
                        <p>We are a tested, trusted &amp; dedicated web development company in Egypt. We provide web development services that help you meet their intended business goals.</p>
                        <router-link to="/contact-us" tag="button" class="btn">CONTACT US</router-link>
                    </div>        
                </div>    
            </div> 
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import'../../_Sass.scss';
.web{
    padding-top:100px;
    padding-bottom:20px;
    .web-company{
        .heading-txt{
            display: flex;
            align-items: center;
            height: 100%;
            h3{
                padding: 10px 0px;
            }
        }
        .heading-img{
            height:300px;
            img,video{
                height: 100%;
                width: 100%;
                padding: 10px;
                object-fit: cover;
            }
        }
    }
    .services{
        .heading{
            padding:20px 0px;
            h4{
                letter-spacing: 2px;
                text-transform: capitalize;
            }
            p{
                line-height: 2;
            }
        }
        .services-panel{
            margin: 15px 0px;
            .desc-img {
                // height:250px;
                width: 100%;
                padding:20px 0px;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
            }
        }
        
    }
    .tech-process{
       .head-txt{
           h4{
               padding: 10px 0px;
           }
       }
       .steps{
           .step{
                background-color:$lightOrange;
                padding: 0px 2px 25px;
                border-radius: 5px;
                margin: 10px 0px;
                height: 320px;
               h6{              
                   display: flex;
                   align-items: center;
                   margin: 0;
                span{
                    margin-right: 5px;
                }
                .custom-size{
                    font-size: 40px;
                    padding-left: 5px;
                    background: -webkit-linear-gradient($orange, #333);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
               }
               p{
                padding: 10px;
                line-height: 2;
                background-color: white;
                font-size: 12px;
                // height: 91%; // change with height of txt
               }
           }
       } 
    }
    .work-together{
        .work{
            text-align: center;
            background-color:$lightblue;
            padding:15px 0px;
            margin: 20px 0px;
            h4{
                letter-spacing: 2px;
            }
            p{
                padding:20px;
                margin: 0;
                line-height: 2;
            }
            button{
                @include mainButton
            }
        }
    }
}
@media (min-width:376px){
.web{
    .tech-process{
       .head-txt{
            h4{
            }
            p{
            }
       }
       .steps{
           .step{
                height:280px;
               h6{              
                span{
                }
                .custom-size{
                }
               }
               p{
                height: 90%; // change with the height of txt
               }
           }
       } 
    }
}
}
@media (max-width:426px){
    .web{
        @include smallFontSizes
    }
}
</style>