<template>
    <div class="product p-4">
        <div class="product-image">
            <img :src="image" alt="">
        </div>
        <div class="product-description">
            <h5>{{name}}</h5>
            <CountDown :deadline="deadline"></CountDown>
            <button class="mt-2" @click="$router.push(route)">{{btnLabel}}</button>
        </div>
    </div>
</template>
<script>
import CountDown from './CountDown.vue';
export default {
    props:{
        image:{
            required:true,
        },
        name:{
            type:String,
            required:true,
        },
        deadline:{
            type:String,
            required:true,
        },
        btnLabel:{
            type:String,
            required:true,
        },
        route:{
            type:Object
        },

    },
    components:{
        CountDown
    },
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.product{
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .product-image{
        width: 50%;
        img{
            max-width: 100%;
           aspect-ratio: 10/9;
        }
    }
    .product-description{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // justify-content: space-between;
        h4{
            word-break: break-word;
        }
        button{
            @include mainButton
        }
    }
}

</style>