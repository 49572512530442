<template>
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-12 ">
                    <div class="intro-head">
                        <h3>Content</h3>
                        <p>Digital storytelling is an integral part of establishing a brand visual. It is still the most engaging content a brand can leverage to connect with its audience.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="content-list">
                        <h6>Video</h6>
                        <h6>Photography</h6>
                        <h6>Animations</h6>
                        <h6>Copywriting</h6>
                        <h6>Social Content</h6>
                        <h6>3D</h6>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="content-img">
                        <video id="video-bg" autoplay loop muted>
                            <source src="../assets\marketing\Content/Black And White Video Of Man Infront Of The Computer.mp4" type="video/mp4">
                            Your browser does not support HTML5 video.
                        </video>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h4>CONTENT MARKETING</h4>
                    <p>It's all about Content Marketing</p>
                </div>
            </div>
            <div class="row content-marketing">
                <div class="col-md-6">
                    <div class="img">
                        <img src="../assets\marketing\Content/marketing-content.png">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="txt">
                        <p>
                           At Redfoxes, we help develop unique engaging brand content marketing that builds awareness, garners a following and cultivates brand advocacy. From creative copywriting, engaging photography, to social and full length video spots, our passion is storytelling and truly believe it all starts with great content.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row services">
                <div class="col-12">
                    <div class="heading">
                        <h4>CONTENT MARKETING SERVICES</h4>
                        <p>The modern marketing ecosystem is a vast and complex web of digital and physical experiences. Brands must adapt their content to work everywhere their audience might find them. Whether its traditional television, a social network, an outdoor billboard, a mobile ad or a random conversation with a digital concierge, we are here to help your brand define those touch point opportunities and create engaging content that transcends.The modern marketing ecosystem is a vast and complex web of digital and physical experiences. Brands must adapt their content to work everywhere their audience might find them. Whether its traditional television, a social network, an outdoor billboard, a mobile ad or a random conversation with a digital concierge, we are here to help your brand define those touch point opportunities and create engaging content that transcends.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="services-panel">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="video" active>
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Video brand storytelling is at the forefront of everything we do. Video content is still the most impactful way to reach any audience.</p>
                                                   <router-link to="/video" tag="button" class="btn">Read More ...</router-link>
                                                </div>
                                            </div>
                                            <div class="desc-img desc-video col-lg-6 col-12">
                                                <video src="../assets/marketing/Content/VIDEO.mp4" muted autoplay></video>
                                                <!-- <img src="../assets\marketing\Content/video.svg"> -->
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Social">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <div>
                                                    <p>Social is the playground where your brand's communication happens. It is the engine that grows a brand following and allows your brand to connect with its consumers.</p>
                                                </div>
                                            </div>
                                            <div class="desc-img desc-video col-lg-6 col-12">
                                                <video src="../assets/marketing/Content/SOCIAL.mp4" muted autoplay></video>
                                                <!-- <img src="../assets\marketing\Content/social.svg"> -->
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Photography">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Photography remains the main driver in telling unique, human and impactful brand stories. It is also the adaptable medium for multi-channel campaigns.</p>
                                            </div>
                                            <div class="desc-img desc-video col-lg-6 col-12">
                                                <video src="../assets/marketing/Content/PHOTOGRAPHY.mp4" muted autoplay></video>
                                                <!-- <img src="../assets\marketing\Content/social.svg"> -->
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Copywriting">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Creative copywriting brings the voice of the brand to life. It sets the tone, defines the brands aspirations and cultivates a relationship with its audience.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Content/COPYWRITING.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="3D">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Some stories are harder to imagine or difficult to tell because of scale and budget, for those instances we like to leverage 3D content.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Content/3D.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Animations">
                                    <b-card-text>
                                        <div class="row">
                                            <div class="desc col-lg-6 col-12">
                                                <p>Whether it's for social or an economical way to tell a brand story, animation content remains an impactful medium to leverage for any brand.</p>
                                            </div>
                                            <div class="desc-img col-lg-6 col-12">
                                                <img src="../assets/marketing/Content/AMINATION.png">
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import '../_Sass.scss';
.content{
    padding-top:100px;
    padding-bottom:30px;
    .intro-head{
        padding: 20px 0px;
        h3{
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
    .content-list{
        h6{
            padding:8px;
            border-radius:1px;
            margin: 10px 0px;
            letter-spacing:2px;
            border-left:3px solid $orange;
        }
    }
    .content-img{
        height:310px;
        width: 100%;
        img,video{
            width: 100%;
            height: 100%;
            padding: 10px;
            object-fit: cover;
        }
    }
    .content-marketing{
        padding: 20px 0px;
        margin: 20px 0px;
        display: flex;
        align-items: center;
        background-color:$lightblue;
        .img{
            height: 250px;
            img{
                width: 100%;
                height:100%;
                padding: 10px 0px;
            }
        }
        p{
            line-height: 2;
            margin: 0;
            text-align: center;
        }
    }
    .services{
        .heading{
            padding:20px 0px;
            h4{
                letter-spacing: 2px;
                text-transform: capitalize;
            }
            p{
                line-height: 2;
            }
        }
        .services-panel{
            margin-top: 10px;
            .desc-img {
                // height:250px;
                padding:20px 0px;
                text-align: center;
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .desc-video {
                // height:250px;
                padding:20px 0px;
                text-align: center;
                video{
                    height: 100%;
                    width: 100%;
                }
            }
            .desc{
                display: flex;
                align-items: center;
                p{
                    line-height: 2;
                }
                button{
                    @include mainButton
                }
            }
        }
        
    }
}
@media (max-width:426px){
    .content{
        @include smallFontSizes
    }
}
</style>