<template>
  <nav class="navbar navbar-expand-lg fixed-top " id="navbar" :class="{'custom-header':custom=='/home' || custom=='/'}">
    <div class="container p-0">
      <!-- Toggler btn -->
      <button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation" > 
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- logo -->
      <router-link  class="navbar-brand" to="/home"><img alt="brand logo" class="d-inline-block align-middle " src="../assets/logo.png" /></router-link>

      <!-- Navbar List -->      
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto mt-lg-0">
          <li class="nav-item">
            <div class="dropdown-wrapper">
              <router-link  to="/marketing" class="nav-link dropdown-toggle" data-toggle="dropdown" >Marketing</router-link >
              <!-- Dropdown List-->
              <div class="dropdown-menu">
                <router-link  to="/marketing" class="dropdown-item" >Marketing Tools</router-link>
                <router-link  to="/branding" class="dropdown-item" >Branding</router-link>
                <router-link  to="/content" class="dropdown-item" >Content</router-link>
                <router-link  to="/strategy" class="dropdown-item" >Strategy</router-link>
              </div>
            </div>
          </li>
          <li class="nav-item show-in-small-media">
            <router-link  to="/content" class="nav-link">Content <span class="sr-only">(current)</span></router-link >
          </li>
          <li class="nav-item show-in-small-media">
            <router-link  to="/services/branding" class="nav-link">Branding <span class="sr-only">(current)</span></router-link >
          </li>
          <li class="nav-item show-in-small-media">
            <router-link  to="/strategy" class="nav-link">Strategy <span class="sr-only">(current)</span></router-link >
          </li>
          <li class="nav-item ">
            <router-link  to="/design" class="nav-link">Design <span class="sr-only">(current)</span></router-link >
          </li>
          <li class="nav-item">
            <div class="dropdown-wrapper">
              <router-link  to="/technology" class="nav-link dropdown-toggle" >Technology</router-link >
              <!-- Dropdown List-->
              <div class="dropdown-menu">
                <router-link  to="/cms-solutions" class="dropdown-item" >CMS</router-link>
                <router-link  to="/eCommerce-solutions" class="dropdown-item" >E-Commerce</router-link>
                <router-link  to="/web-solutions" class="dropdown-item" >Web</router-link>
                <router-link  to="/mobile-solutions" class="dropdown-item" >Mobile</router-link>
              </div>
            </div>
          </li>
          <li class="nav-item ">
            <router-link  to="/iot" class="nav-link">IOT <span class="sr-only">(current)</span></router-link >
          </li>
          <li class="nav-item ">
            <router-link  to="/products" class="nav-link">Products <span class="sr-only">(current)</span></router-link >
          </li>
          <li class="nav-item">
            <router-link  to="/career" class="nav-link" >Career <span class="sr-only">(current)</span></router-link  >
          </li>
          <li class="nav-item ">
            <router-link  to="/contact-us" class="nav-link" >Contact us <span class="sr-only">(current)</span></router-link >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "Header",
  data(){
    return{
      custom:this.$route.path,
    }
  },
  watch:{
    $route(){
      this.custom=this.$route.path
      // console.log(this.custom)
    }
  },
  mounted() {
    //navbar show on scroll up //
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("navbar").style.top = "0";
      } else {
        document.getElementById("navbar").style.top = "-120px";
      }
      prevScrollpos = currentScrollPos;
    };
    //navbar show on scroll up //
  },
};
</script>

<style lang="scss" scoped>
@import '../_Sass.scss';
.navbar {
  background-color:$lightOrange;
  padding: 0px;
  transition: top 0.5s;
  
  .container {
    .navbar-toggler {
      margin-left:1rem;
    }
    .navbar-brand {
      img {
        width:80px;
        height:80px;
      }
    }
    #navbarResponsive {
      .navbar-nav {
        .show-in-small-media{
          display: none;
        }
        .nav-item {
            font-size:18px;
            margin: 0px 5px;
            padding: 0 5px;
            text-align: center;
            a {
              color:$black;
              border-radius:3px;
              &:hover{transition: all 1s ease;}
              &.router-link-exact-active {
                background-color:$orange;
                color: white;
              }
            }
            &:hover{
              .nav-link{
                background-color:$orange;
                color: white;
              }
              .dropdown-menu {
                display: block;
              }
            }
            .dropdown-menu {
              left:auto;
              top: 65px;
              width:200px;
              display: none;
              background-color:$lightOrange;
            }
            .dropdown-item {
              margin: 10px 0px;
              &:hover{
                background-color:$orange;
                color: white;
              }
            }
        }
      }
    }
  }
}

.navbar.custom-header{
  background-color:rgba($black,0.8);
      #navbarResponsive {
      .navbar-nav {
        .nav-item {
            a {
              color:white !important;
            }
            .dropdown-menu {
              background-color:transparent !important;
            }
        }
      }
    }
}



@media (max-width:976px) {
  .navbar {
      background-color:$lightOrange;
    .container {
      .custom-toggler .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(218, 41, 28, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
      .custom-toggler.navbar-toggler {
        border-color: $orange;
      } 
      #navbarResponsive {
        .navbar-nav {
            .show-in-small-media{
            display: block;
          }
          .nav-item {
            :hover {
              border: none;
            }
            a {
              &.router-link-exact-active {
                border: none;
                  &:after {
                  content: "";
                  display: block;
                  width:70px;
                  height: 3px;
                  background:$orange;
                  border-radius: 3px;
                  text-align: center;
                  margin:0px auto;
              }
              }
            }
            .dropdown-toggle{
              &:after{
                content: none;
              }
            }
            a {
            }
            .dropdown-menu {
              display: none;
            }
              &:hover .dropdown-menu {
              display: none;
            }
            .dropdown-item {
            }
          }
        }
      }
    }
  }
}
@media (max-width:426px){
  .navbar{
         @include smallFontSizes
  }
}
</style>
